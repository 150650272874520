import React, { useEffect, useState, useRef } from "react"
import { connect, useDispatch } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { useFormik } from "formik"
import * as Yup from "yup"
import { addService } from "../../store/Services/actions"
import Dropzone from "react-dropzone"
import { searchCompany, searchCompanyNulled } from "../../store/Company/actions"
import { getFilters } from "../../store/Filters/actions"

const Add = props => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState("all")
  const [limit, setLimit] = useState(100)
  const inputRef = useRef(null)
  const [text, setText] = useState(EditorState.createEmpty())
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFiles, setselectedFiles] = useState([])

  const [searchForm, setSearchForm] = useState("")
  const [companyId, setCompanyId] = useState("")

  const onEditorText = editorState => {
    setText(editorState)
  }
  useEffect(() => {
    dispatch(searchCompanyNulled())
    let item = {
      type_id : 1,
      type: 'service'
    }
    dispatch(getFilters(item))
    let item2 = {
      type_id : 2,
      type: 'service'
    }
    dispatch(getFilters(item2))
    let item3 = {
      type_id : 3,
      type: 'service'
    }
    dispatch(getFilters(item3))
    let item4 = {
      type_id : 4,
      type: 'service'
    }
    dispatch(getFilters(item4))
  }, [])

  useEffect(() => {
    setText(EditorState.createEmpty())
    inputRef.current.value = null
    validation.resetForm()
    dispatch(searchCompanyNulled())
    setSearchForm("")
    setCompanyId("")
    setselectedFiles([])
  }, [props.Services.updateResult])


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      subTitle: "",
      phone: "",
      email: "",
      webPage: "",
      description: "",
      text: "",
      company_id: "",

      private_sector: "",
      public_sector: "",
      products: "",
      regions: "",

    },
    validationSchema: Yup.object({
      title: Yup.string().required("შეიყვანეთ სათაური"),
      subTitle: Yup.string().required("შეიყვანეთ ქვე სათაური"),
      phone: Yup.string().required("შეიყვანეთ ტელეფონი"),
      email: Yup.string().required("შეიყვანეთ ელ-ფოსტა"),
      webPage: Yup.string().required("შეიყვანეთ ვებ-გვერდი"),
      description: Yup.string().required("შეიყვანეთ მცირე აღწერილობა").max(250, 'აღწერილობა უნდა იყოს მაქსიმუმ 250 სიმბოლო'),
    }),
    onSubmit: (values, { resetForm }) => {

      let sendData = new FormData()

      sendData.append("title", values.title)
      sendData.append("meta_title", values.meta_title === "undefined" ? "" : values.meta_title)
      sendData.append("meta_description", values.meta_description === "undefined" ? "" : values.meta_description)
      sendData.append("meta_keywords", values.meta_keywords === "undefined" ? "" : values.meta_keywords)
      sendData.append("sub_title", values.subTitle)
      sendData.append("phone", values.phone)
      sendData.append("email", values.email)
      sendData.append("web_page", values.webPage)
      sendData.append("description", values.description)
      sendData.append("text", draftToHtml(convertToRaw(text.getCurrentContent())))
      sendData.append("file", selectedFile)
      sendData.append("company_id", companyId)

      sendData.append("private_sector", values.private_sector)
      sendData.append("public_sector", values.public_sector)
      sendData.append("products", values.products)
      sendData.append("regions", values.regions)

      selectedFiles.forEach((item) => {
        sendData.append("images[]", item)
      })

      dispatch(addService(sendData, 1))
    }
  })

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }


  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )
    setselectedFiles(files)
  }

  const handleSearch = () => {
    let item = {
      search: searchForm
    }
    dispatch(searchCompany(item))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title=""
                       breadcrumbItem={props.match.params.type === "1" ? "სერვისის დამატება" : "ოფერის დამატება"} />
          <Card>
            <CardBody>
              <Row>

              </Row>
              <Row>
                <Col lg={12}>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >

                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label className="form-label">სათაური</Label>
                          <Input
                            name="title"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title || ""}
                            invalid={
                              validation.touched.title && validation.errors.title ? true : false
                            }
                          />
                          {validation.touched.title && validation.errors.title ? (
                            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label className="form-label">ქვე სათაური</Label>
                          <Input
                            name="subTitle"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.subTitle || ""}
                            invalid={
                              validation.touched.subTitle && validation.errors.subTitle ? true : false
                            }
                          />
                          {validation.touched.subTitle && validation.errors.subTitle ? (
                            <FormFeedback type="invalid">{validation.errors.subTitle}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg="4">
                        <div className="mb-3">
                          <Label className="form-label">თეგი Title</Label>
                          <Input
                            name="meta_title"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.meta_title || ""}
                          />
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label className="form-label">თეგი Description</Label>
                          <Input
                            name="meta_description"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.meta_description || ""}
                          />
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label className="form-label">თეგი Keywords</Label>
                          <Input
                            name="meta_keywords"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.meta_keywords || ""}
                          />

                        </div>
                      </Col>

                      <Col lg="4">
                        <div className="mb-3">
                          <Label className="form-label">ტელეფონი</Label>
                          <Input
                            name="phone"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone && validation.errors.phone ? true : false
                            }
                          />
                          {validation.touched.phone && validation.errors.phone ? (
                            <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg="4">
                        <div className="mb-3">
                          <Label className="form-label">ელ-ფოსტა</Label>
                          <Input
                            name="email"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label className="form-label">ვებ-გვერდი</Label>
                          <Input
                            name="webPage"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.webPage || ""}
                            invalid={
                              validation.touched.webPage && validation.errors.webPage ? true : false
                            }
                          />
                          {validation.touched.webPage && validation.errors.webPage ? (
                            <FormFeedback type="invalid">{validation.errors.webPage}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>


                      {
                        props.match.params.type === "1"
                        ?  <>
                            <Col lg="12">
                              <div className="mb-3">
                                <h4>ფილტრი</h4>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label className="form-label">კერძო სექტორი</Label>
                                <select
                                  name={'private_sector'}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.private_sector || ""}
                                  className="form-select">
                                  <option>აირჩიერთ ერთ-ერთი</option>
                                  {
                                    props.Services.privateSectorResult.data.map((item, key) =>{
                                      return (
                                        <React.Fragment key={key}>
                                          <option>{item.name}</option>
                                        </React.Fragment>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label className="form-label">საჯარო სექტორი</Label>
                                <select
                                  name={'public_sector'}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.public_sector || ""}
                                  className="form-select">
                                  <option>აირჩიერთ ერთ-ერთი</option>
                                  {
                                    props.Services.publicSectorResult.data.map((item, key) =>{
                                      return (
                                        <React.Fragment key={key}>
                                          <option>{item.name}</option>
                                        </React.Fragment>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label className="form-label">პროდუქტი</Label>
                                <select
                                  name={'products'}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.products || ""}
                                  className="form-select">
                                  <option>აირჩიერთ ერთ-ერთი</option>
                                  {
                                    props.Services.productResult.data.map((item, key) =>{
                                      return (
                                        <React.Fragment key={key}>
                                          <option>{item.name}</option>
                                        </React.Fragment>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label className="form-label">რეგიონი</Label>
                                <select
                                  name={'regions'}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.regions || ""}
                                  className="form-select">
                                  <option>აირჩიერთ ერთ-ერთი</option>
                                  {
                                    props.Services.regionResult.data.map((item, key) =>{
                                      return (
                                        <React.Fragment key={key}>
                                          <option>{item.name}</option>
                                        </React.Fragment>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                            </Col>
                        </>
                          :null
                      }



                      <Col lg="12">
                        <div className="mb-3">
                          <Label className="form-label">მცირე აღწერილობა</Label>
                          <Input
                            name="description"
                            className="form-control"
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            invalid={
                              validation.touched.description && validation.errors.description ? true : false
                            }
                          />
                          {validation.touched.description && validation.errors.description ? (
                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg="12">
                        <div className="mb-3">
                          <Label className="form-label">ტექსტი</Label>
                          <Editor
                            editorState={text}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            onEditorStateChange={onEditorText}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <h3>ფოტოსურათები</h3>
                      </Col>
                      <Col lg="6">
                        <h3>კომპანიის არჩევა</h3>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label className="form-label">მთავარი ფოტოსურათი</Label>
                          <input ref={inputRef} type="file"
                                 className="form-control"
                                 onChange={(e) => setSelectedFile(e.target.files[0])}
                          />
                          {validation.touched.file && validation.errors.file ? (
                            <FormFeedback type="invalid">{validation.errors.file}</FormFeedback>
                          ) : null}
                        </div>
                        <Label className="form-label">დამატებითი ფოტოსურათები</Label>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted uil uil-cloud-upload" />
                                </div>
                                <h4>დაამატეთ ფოტოსურათები.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div className="dropzone-previews mt-3" id="file-previews">
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </Col>
                      <Col lg="6">
                        <Row>
                          <Col lg="10">
                            <div className="mb-3">
                              <Label className="form-label"> მიუთითეთ კომპანიის სახელი </Label>
                              <Input
                                name="searchForm"
                                className="form-control"
                                type="text"
                                onChange={(e) => setSearchForm(e.target.value)}
                                value={searchForm || ""}
                              />
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="mb-3">
                              <Label className="form-label"> . </Label>
                              <Button
                                color="primary"
                                className="btn-block inner"
                                id="unknown-btn"
                                style={{ width: "100%" }}
                                onClick={handleSearch}
                              >
                                ძებნა
                              </Button>
                            </div>
                          </Col>
                          <Col md="12">
                            <div>
                              <h5 className="font-size-14 mb-3">
                                <i className="mdi mdi-arrow-right text-primary me-1"></i>
                                მონიშნეთ კომპანია {companyId}
                              </h5>
                              <div className="vstack gap-2">
                                {
                                  props.Company.dataSearch.map((item, key) => {
                                    return (
                                      <div className="form-check" key={key}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadios"
                                          id={item.id}
                                          value={item.id}
                                          onChange={(e) => {
                                            setCompanyId(e.target.value)
                                          }}
                                          // defaultChecked
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={item.id}
                                        >
                                          {
                                            item.title
                                          }
                                          {" "}
                                          #: {item.id}
                                        </label>
                                      </div>
                                    )
                                  })
                                }

                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>


                    </Row>
                    <Row>
                      <Col className={"pt-3"}>

                      </Col>
                    </Row>
                    <Row>

                      <Col>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          დამატება
                        </button>
                      </Col>
                    </Row>


                  </Form>
                </Col>
              </Row>

            </CardBody>
          </Card>

        </div>
      </div>
    </React.Fragment>

  )
}


const mapStateToProps = state => {
  const {
    Services,
    Company
  } = state
  return {
    Services,
    Company
  }
}
export default withRouter(connect(mapStateToProps)(Add))

