import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import ObjectColumns from "./ObjectColumns"
import { getServices } from "../../store/Services/actions"
import { getRequiredLinks } from "../../store/RequiredLinks/actions"
import RequiredLinks from "../../store/RequiredLinks/reducer"

const List = props => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [skip, setSkip] = useState(0)
  const [type, setType] = useState("all")
  const [limit, setLimit] = useState(100)

  useEffect(() => {
    let item = {
      page:1,
      limit : process.env.REACT_APP_LIST_LIMIT,
      category :''
    }
    dispatch(getRequiredLinks(item))
  }, [type])

  const handlePagination = (page) => {
    let item = {
      page:page,
      limit : process.env.REACT_APP_LIST_LIMIT,
      category :''
    }
    dispatch(getRequiredLinks(item))
  }

  const handleChangeCategory = (category) =>{
    let item = {
      page: 1,
      limit : process.env.REACT_APP_LIST_LIMIT,
      category :category
    }
    dispatch(getRequiredLinks(item))
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="" breadcrumbItem="საჭირო ბმულები" />

          <Card>
            <CardBody>
              <div className="mb-3">
                <Label className="form-label">კატეგორია</Label>
                <select
                  onChange={(e) => handleChangeCategory(e.target.value)}
                  name="category_id"
                  className="form-control m-input">
                  <option value="">ყველა</option>
                  <option value="ფინანსები/ბუღალტერია">ფინანსები/ბუღალტერია</option>
                  <option value="იურიდიული">იურიდიული</option>
                  <option value="გაყიდვები/მარკეტინგი">გაყიდვები/მარკეტინგი</option>
                  <option value="ადამიანური რესურსები">ადამიანური რესურსები</option>
                  <option value="ექსპორტი">ექსპორტი</option>
                  <option value="სხვა">სხვა</option>
                </select>
              </div>


            <Col lg={12}>
              <ObjectColumns
                page="index"
                pagination={handlePagination}
                data={props.RequiredLinks.result.data}
                dataTotal={props.RequiredLinks.result.total}
              />
            </Col>

            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>

  )
}


const mapStateToProps = state => {
  const {
    RequiredLinks
  } = state
  return {
    RequiredLinks
  }
}
export default withRouter(connect(mapStateToProps)(List))
