import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Row } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import ObjectColumns from "./ObjectColumns"
import { getServices } from "../../store/Services/actions"
import { getGrants } from "../../store/Grants/actions"

const List = props => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [skip, setSkip] = useState(0)
  const [type, setType] = useState("all")
  const [limit, setLimit] = useState(100)

  useEffect(() => {
    let item = {
      page:1,
      limit : process.env.REACT_APP_LIST_LIMIT
    }
    dispatch(getGrants(item))
  }, [type])

  const handlePagination = (page) => {
    let item = {
      page:page,
      limit : process.env.REACT_APP_LIST_LIMIT
    }
    dispatch(getGrants(item))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="სია" breadcrumbItem="სერვისები" />
          <Row>
            <Col lg={12}>
              <ObjectColumns
                page="index"
                pagination={handlePagination}
                data={props.Grants.result.data}
                dataTotal={props.Grants.result.total}
              />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>

  )
}


const mapStateToProps = state => {
  const {
    Grants
  } = state
  return {
    Grants
  }
}
export default withRouter(connect(mapStateToProps)(List))
