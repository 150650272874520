import {
  GET_REVIEWS_REQUEST,
  GET_REVIEW_REQUEST,
  UPDATE_REVIEW_REQUEST,
  DELETE_REVIEW_REQUEST
} from "./actionTypes"


export const getReview= (data) => ({
  type: GET_REVIEW_REQUEST,
  payload :data
})

export const getReviews = (data) => ({
  type: GET_REVIEWS_REQUEST,
  payload :data
})

export const updateReview = (data, id) => ({
  type: UPDATE_REVIEW_REQUEST,
  payload :{data,id}
})

export const deleteReview = (data) => ({
  type: DELETE_REVIEW_REQUEST,
  payload :data
})
