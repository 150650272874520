export const CREATE_REVIEW_REQUEST = "CREATE_REVIEW_REQUEST"
export const CREATE_REVIEW_REQUEST_SUCCESS = "CREATE_REVIEW_REQUEST_SUCCESS"

export const GET_REVIEWS_REQUEST = "GET_REVIEWS_REQUEST"
export const GET_REVIEWS_REQUEST_SUCCESS = "GET_REVIEWS_REQUEST_SUCCESS"

export const GET_REVIEW_REQUEST = "GET_REVIEW_REQUEST"
export const GET_REVIEW_REQUEST_SUCCESS = "GET_REVIEW_REQUEST_SUCCESS"

export const UPDATE_REVIEW_REQUEST = "UPDATE_REVIEW_REQUEST"
export const UPDATE_REVIEW_REQUEST_SUCCESS = "UPDATE_REVIEW_REQUEST_SUCCESS"

export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST"
export const DELETE_REVIEW_REQUEST_SUCCESS = "DELETE_REVIEW_REQUEST_SUCCESS"

