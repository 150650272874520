import {
  ADD_REQUIRED_LINK_REQUEST,
  GET_REQUIRED_LINKS_REQUEST,
  GET_REQUIRED_LINK_REQUEST,
  UPDATE_REQUIRED_LINK_REQUEST,
  DELETE_REQUIRED_LINK_REQUEST
} from "./actionTypes"

export const addRequiredLink = (data) => ({
  type: ADD_REQUIRED_LINK_REQUEST,
  payload :data
})

export const getRequiredLinks = (data) => ({
  type: GET_REQUIRED_LINKS_REQUEST,
  payload :data
})

export const getRequiredLink = (data) => ({
  type: GET_REQUIRED_LINK_REQUEST,
  payload :data
})

export const updateRequiredLink = (data, id) => ({
  type: UPDATE_REQUIRED_LINK_REQUEST,
  payload :{data,id}
})

export const deleteRequiredLink = (data) => ({
  type: DELETE_REQUIRED_LINK_REQUEST,
  payload :data
})
