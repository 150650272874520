import React from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import { Link, withRouter } from "react-router-dom"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import MiniWidget from "./mini-widget";
import SalesAnalyticsChart from "./salesanalytics-chart";
import TopProduct from "./topselling-product";
import TopUser from "./topuser";
import RecentActivity from "./recent-activity";
import SocialSource from "./socialsource";
import LatestTransaction from "./latest-transaction";

//Import Image
import setupanalytics from "../../assets/images/setup-analytics-amico.svg";
import { connect } from "react-redux"

const series1 = [{
  data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
}];

const options1 = {
  fill: {
    colors: ['#5b73e8']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    },
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const series2 = [70];

const options2 = {
  fill: {
    colors: ['#34c38f']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const series3 = [55];

const options3 = {
  fill: {
    colors: ['#5b73e8']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const series4 = [{
  data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
}];

const options4 = {

  fill: {
    colors: ['#f1b44c']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    },
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const Dashboard = (props) => {

  const reports = [
    {
      id: 1,
      icon: "mdi mdi-arrow-up-bold",
      title: "Total Revenue",
      value: 34152,
      prefix: "$",
      suffix: "",
      badgeValue: "2.65%",
      decimal: 0,
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      color: "success",
      desc: "since last week",
      series: series1,
      options: options1,

    },
    {
      id: 2,
      icon: "mdi mdi-arrow-down-bold",
      title: "Orders",
      value: 5643,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0.82%",
      color: "danger",
      desc: "since last week",
      series: series2,
      options: options2,
    },
    {
      id: 3,
      icon: "mdi mdi-arrow-down-bold",
      title: "Customers",
      value: 45254,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      badgeValue: "6.24%",
      color: "danger",
      desc: "since last week",
      series: series3,
      options: options3,
    },
    {
      id: 4,
      icon: "mdi mdi-arrow-up-bold",
      title: "Growth",
      value: 12.58,
      decimal: 2,
      prefix: "+",
      suffix: "%",
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      badgeValue: "10.51%",
      color: "success",
      desc: "since last week",
      series: series4,
      options: options4,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="მთავარი" />
          <Row>
            <Col md={6} xl={3} >
              <Card>
                <CardBody>
                  <div>
                    <h4 className="mb-1 mt-1">{props.Dashboard.result.company_service} </h4>
                    <p className="text-muted mb-0">სერვისები</p>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={6} xl={3} >
              <Card>
                <CardBody>
                  <div>
                    <h4 className="mb-1 mt-1">{props.Dashboard.result.offer} </h4>
                    <p className="text-muted mb-0">ოფერები</p>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={6} xl={3} >
              <Card>
                <CardBody>
                  <div>
                    <h4 className="mb-1 mt-1">{props.Dashboard.result.grant} </h4>
                    <p className="text-muted mb-0">გრანტები</p>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={6} xl={3} >
              <Card>
                <CardBody>
                  <div>
                    <h4 className="mb-1 mt-1">{props.Dashboard.result.blog} </h4>
                    <p className="text-muted mb-0">სიახლები</p>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={6} xl={3} >
              <Card>
                <CardBody>
                  <div>
                    <h4 className="mb-1 mt-1">{props.Dashboard.result.required_link} </h4>
                    <p className="text-muted mb-0">საჭირო ბმულები</p>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={6} xl={3} >
              <Card>
                <CardBody>
                  <div>
                    <h4 className="mb-1 mt-1">{props.Dashboard.result.investment} </h4>
                    <p className="text-muted mb-0">ინვესტიცია</p>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={6} xl={3} >
              <Card>
                <CardBody>
                  <div>
                    <h4 className="mb-1 mt-1">{props.Dashboard.result.company} </h4>
                    <p className="text-muted mb-0">კომპანიები</p>
                  </div>
                </CardBody>
              </Card>
            </Col>



          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};


const mapStateToProps = state => {
  const {
    Dashboard
  } = state
  return {
    Dashboard
  }
}
export default withRouter(connect(mapStateToProps)(Dashboard))

