import {
  ADD_INVESTMENT_REQUEST,
  GET_INVESTMENTS_REQUEST,
  GET_INVESTMENT_REQUEST,
  UPDATE_INVESTMENT_REQUEST,
  DELETE_INVESTMENT_REQUEST
} from "./actionTypes"

export const addInvestment = (data) => ({
  type: ADD_INVESTMENT_REQUEST,
  payload :data
})

export const getInvestments = (data) => ({
  type: GET_INVESTMENTS_REQUEST,
  payload :data
})

export const getInvestment = (data) => ({
  type: GET_INVESTMENT_REQUEST,
  payload :data
})

export const updateInvestment = (data, id) => ({
  type: UPDATE_INVESTMENT_REQUEST,
  payload :{data,id}
})

export const deleteInvestment = (data) => ({
  type: DELETE_INVESTMENT_REQUEST,
  payload :data
})
