export const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST"
export const ADD_COMPANY_REQUEST_SUCCESS = "ADD_COMPANY_REQUEST_SUCCESS"

export const GET_COMPANIES_REQUEST = "GET_COMPANIES_REQUEST"
export const GET_COMPANIES_REQUEST_SUCCESS = "GET_COMPANIES_REQUEST_SUCCESS"

export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST"
export const GET_COMPANY_REQUEST_SUCCESS = "GET_COMPANY_REQUEST_SUCCESS"

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST"
export const UPDATE_COMPANY_REQUEST_SUCCESS = "UPDATE_COMPANY_REQUEST_SUCCESS"

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST"
export const DELETE_COMPANY_REQUEST_SUCCESS = "DELETE_COMPANY_REQUEST_SUCCESS"

export const SEARCH_COMPANY_REQUEST = "SEARCH_COMPANY_REQUEST"
export const SEARCH_COMPANY_REQUEST_SUCCESS = "SEARCH_COMPANY_REQUEST_SUCCESS"

export const SEARCH_COMPANY_NULLED_REQUEST = "SEARCH_COMPANY_NULLED_REQUEST"


