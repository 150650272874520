import { call, put, takeEvery } from "redux-saga/effects"
import {
  LOGIN_ADMIN_REQUEST,
  CHECK_ADMIN_STATUS_REQUEST, CHECK_ADMIN_STATUS_REQUEST_SUCCESS, LOGOUT_REQUEST
} from "./actionTypes"


import {
  signUpEmailAlreadyRegistred,
  signInEmailOrPasswordIncorect
} from "../../components/Notifications/authentication"
import { checkAdmin } from "./actions"



function* loginAdminSaga({ payload }) {
  try {
    const callBack = yield call(loginAdminSagaAsync, payload)
    if (callBack.success) {
      localStorage.setItem('token', callBack.result.data.bearer);
      yield put(checkAdmin(payload))
    } else {
      signInEmailOrPasswordIncorect("error")
      localStorage.removeItem("token")
    }

  } catch (err) {
    console.error(err)
  }
}


const loginAdminSagaAsync = async (item) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/signin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(item.values)
  })
  return await response.json()
}

function* checkAdminSaga({ payload }) {
  try {
    if (localStorage.getItem("token")) {
      const callBack = yield call(checkAdminSagaAsync, payload)
      if (callBack.success) {
        yield put({type: CHECK_ADMIN_STATUS_REQUEST_SUCCESS, callBack})
        if (payload.history !== undefined) {
          payload.history.push("/dashboard")
        }
      }else {
        localStorage.removeItem("token")
      }
    }
  } catch (err) {
    console.error(err)
  }
}


const checkAdminSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/auth/check`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': `Bearer ${jwt}`,
    },
  })
  return await response.json()
}
function* logOutSaga({ payload }) {
  try {
    const callBack = yield call(logOutSagaAsync, payload)
    if (callBack.success) {
      localStorage.removeItem("token")
      payload.history.push("/login")
    }
  } catch (err) {
    console.error(err)
  }
}


const logOutSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/logout`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': `Bearer ${jwt}`,
    },
  })
  return await response.json()
}

function* authSaga() {
  yield takeEvery(LOGIN_ADMIN_REQUEST, loginAdminSaga)
  yield takeEvery(CHECK_ADMIN_STATUS_REQUEST, checkAdminSaga)
  yield takeEvery(LOGOUT_REQUEST, logOutSaga)
}

export default authSaga
