import React, { useEffect, useState, useRef } from "react"
import { connect, useDispatch } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { useFormik } from "formik"
import * as Yup from "yup"
import { addPost } from "../../store/Post/actions"
import { getPage, updatePage } from "../../store/Pages/actions"
import { getService } from "../../store/Services/actions"
import Pages from "../../store/Pages/reducer"

const PageInvestments = props => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState("")

  const inputRef = useRef(null)
  const [selectedFile, setSelectedFile] = useState(null)

  useEffect(() => {
    let item = {
      id: 'investments'
    }
    dispatch(getPage(item))
  }, [])

  useEffect(() => {
    setFile(props.Pages.data.file)
  }, [props.Pages.data.file])

  useEffect(() => {
    let item = {
      id: 'investments'
    }
    dispatch(getPage(item))

  }, [props.Pages.updateResultEdit])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      meta_title: props.Pages.data.meta_title,
      meta_description: props.Pages.data.meta_description,
      meta_keywords: props.Pages.data.meta_keywords
    },
    validationSchema: Yup.object({}),
    onSubmit: (values, { resetForm }) => {
      let sendData = new FormData()
      sendData.append("page", 'investments')
      sendData.append("meta_title", values.meta_title)
      sendData.append("meta_description", values.meta_description)
      sendData.append("meta_keywords", values.meta_keywords)
      sendData.append("file", selectedFile)

      dispatch(updatePage(sendData))
    }
  })


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="" breadcrumbItem="ინვესტიციის გვერდის რედაქტირება" />
          <Card>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >

                    <Row>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label className="form-label">თეგი Title</Label>
                          <Input
                            name="meta_title"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.meta_title || ""}
                          />
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label className="form-label">თეგი Description</Label>
                          <Input
                            name="meta_description"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.meta_description || ""}
                          />
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label className="form-label">თეგი Keywords</Label>
                          <Input
                            name="meta_keywords"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.meta_keywords || ""}
                          />

                        </div>
                      </Col>


                      <Col lg="6">
                        <div className="mb-3">
                          <Label className="form-label">მთავარი ფოტოსურათი</Label>
                          <input ref={inputRef} type="file"
                                 className="form-control"
                                 onChange={(e) => setSelectedFile(e.target.files[0])}
                          />
                          {validation.touched.file && validation.errors.file ? (
                            <FormFeedback type="invalid">{validation.errors.file}</FormFeedback>
                          ) : null}
                        </div>

                        {
                          file
                            ?
                            <div className="mb-3">
                              <img
                                style={{ height: "150px" }}
                                src={`${file}`} />
                            </div>
                            : null
                        }

                      </Col>
                    </Row>
                    <Row>

                      <Col>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          დამახსოვრება
                        </button>
                      </Col>
                    </Row>


                  </Form>
                </Col>
              </Row>

            </CardBody>
          </Card>

        </div>
      </div>
    </React.Fragment>

  )
}

const mapStateToProps = state => {
  const {
    Pages
  } = state
  return {
    Pages
  }
}
export default withRouter(connect(mapStateToProps)(PageInvestments))

