import React, { useEffect, useState, useRef } from "react"
import { connect, useDispatch } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { useFormik } from "formik"
import * as Yup from "yup"
import Dropzone from "react-dropzone"
import { searchCompany, searchCompanyNulled } from "../../store/Company/actions"
import { addInvestment, getInvestment, getInvestments, updateInvestment } from "../../store/Investments/actions"
import { deleteFile } from "../../store/Photos/actions"

const Add = props => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const inputRef = useRef(null)
  const [text, setText] = useState(EditorState.createEmpty())
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFiles, setselectedFiles] = useState([])

  const [searchForm, setSearchForm] = useState("")
  const [companyId, setCompanyId] = useState("")

  const onEditorText = editorState => {
    setText(editorState)
  }
  useEffect(() => {
    dispatch(searchCompanyNulled())
  }, [])

  useEffect(() => {
    if (isLoading) {
      setselectedFiles([])
      if (props.match.params.id) {
        let item = {
          id: props.match.params.id
        }
        dispatch(getInvestment(item))
      }
    }
  }, [props.Investments.updateResultEdit])


  useEffect(() => {
    if (props.match.params.id) {
      let item = {
        id: props.match.params.id
      }
      dispatch(getInvestment(item))
    }

  }, [props.match.params.id])

  useEffect(() => {
    setIsLoading(true)
    setText(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(props.Investments.data.text)
        ))
    )

  }, [props.Investments.data.text])


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: props.Investments.data.id || "",
      title: props.Investments.data.title || "",

      meta_title: props.Investments.data.meta_title || "",
      meta_description: props.Investments.data.meta_description || "",
      meta_keywords: props.Investments.data.meta_keywords || "",

      amount: props.Investments.data.amount || "",

      currency: props.Investments.data.currency || "",

      description: props.Investments.data.description || "",

      company_name: props.Investments.data.company_name || "",

      industry: props.Investments.data.industry || "",
      location: props.Investments.data.location || "",
      investment_type: props.Investments.data.investment_type || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("შეიყვანეთ სათაური"),
      amount: Yup.string().required("შეიყვანეთ თანხა"),
      description: Yup.string().required("შეიყვანეთ მცირე აღწერილობა").max(250, 'აღწერილობა უნდა იყოს მაქსიმუმ 250 სიმბოლო'),
      company_name: Yup.string().required("შეიყვანეთ კომპანიის სახელი")
    }),
    onSubmit: (values, { resetForm }) => {

      let sendData = new FormData()
      sendData.append("title", values.title)
      sendData.append("meta_title", values.meta_title)
      sendData.append("meta_description", values.meta_description)
      sendData.append("meta_keywords", values.meta_keywords)

      sendData.append("amount", values.amount)
      sendData.append("currency", values.currency)

      sendData.append("description", values.description)
      sendData.append("text", draftToHtml(convertToRaw(text.getCurrentContent())))
      sendData.append("file", selectedFile)

      sendData.append("company_name", values.company_name)
      sendData.append("industry", values.industry)
      sendData.append("location", values.location)

      selectedFiles.forEach((item) => {
        sendData.append("images[]", item)
      })

      dispatch(updateInvestment(sendData, props.match.params.id))

    }
  })


  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )
    setselectedFiles(files)
  }


  const handleSearch = () => {
    let item = {
      search: searchForm
    }
    dispatch(searchCompany(item))
  }


  const handleDeleteFile = (f, i) => {
    let item = {
      id: f,
      key: i,
      type: "investments"
    }
    dispatch(deleteFile(item))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="" breadcrumbItem="ინვესტიცის რედაქტირება" />
          <Card>
            <CardBody>
              <Row>
                {
                  props.Investments.data.id
                    ?
                    <Col lg={12}>
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >

                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="form-label">სათაური</Label>
                              <Input
                                name="title"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title && validation.errors.title ? true : false
                                }
                              />
                              {validation.touched.title && validation.errors.title ? (
                                <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label className="form-label">თანხა</Label>
                              <Input
                                name="amount"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.amount || ""}
                                invalid={
                                  validation.touched.amount && validation.errors.subTitle ? true : false
                                }
                              />
                              {validation.touched.amount && validation.errors.amount ? (
                                <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label className="form-label">ვალუტა</Label>

                              <select
                                name="currency"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.currency || ""}
                                className="form-control m-input">
                                <option value="USD">USD</option>
                                <option value="GEL">GEL</option>
                                <option value="EUR">EUR</option>
                              </select>

                            </div>
                          </Col>

                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">თეგი title</Label>
                              <Input
                                name="meta_title"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.meta_title || ""}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">თეგი description</Label>
                              <Input
                                name="meta_description"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.meta_description || ""}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">თეგი keywords</Label>
                              <Input
                                name="meta_keywords"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.meta_keywords || ""}
                              />

                            </div>
                          </Col>

                          <Col lg="12">
                            <div className="mb-3">
                              <h4>ფილტრი</h4>
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">ინვესტიცის ტიპი</Label>
                              <select
                                name={'investment_type'}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.investment_type || ""}
                                className="form-select">
                                <option value={'იყიდება ბიზნესი'}>იყიდება ბიზნესი</option>
                                <option value={'საინვესტიციო პროექტები'}>საინვესტიციო პროექტები</option>

                              </select>
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">ინდუსტრია</Label>
                              <select
                                name={'industry'}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.industry || ""}
                                className="form-select">
                                <option>აირჩიერთ ერთ-ერთი</option>
                                {
                                  props.Investments.industryResult.data.map((item, key) =>{
                                    return (
                                      <React.Fragment key={key}>
                                        <option>{item.name}</option>
                                      </React.Fragment>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">ლოკაცია</Label>
                              <select
                                name={'location'}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.location || ""}
                                className="form-select">
                                <option>აირჩიერთ ერთ-ერთი</option>
                                {
                                  props.Investments.locationResult.data.map((item, key) =>{
                                    return (
                                      <React.Fragment key={key}>
                                        <option>{item.name}</option>
                                      </React.Fragment>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </Col>

                          <Col lg="12">
                            <div className="mb-3">
                              <Label className="form-label">მცირე აღწერილობა</Label>
                              <Input
                                name="description"
                                className="form-control"
                                type="textarea"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description && validation.errors.description ? true : false
                                }
                              />
                              {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div className="mb-3">
                              <Label className="form-label">ტექსტი</Label>
                              <Editor
                                editorState={text}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                onEditorStateChange={onEditorText}
                              />
                            </div>
                          </Col>


                          <Col lg="6">
                            <h3>ფოტოსურათები</h3>
                          </Col>
                          <Col lg="6">
                            <h3>კომპანიის არჩევა</h3>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="form-label">მთავარი ფოტოსურათი</Label>
                              <input ref={inputRef} type="file"
                                     className="form-control"
                                     onChange={(e) => setSelectedFile(e.target.files[0])}
                              />
                              {validation.touched.file && validation.errors.file ? (
                                <FormFeedback type="invalid">{validation.errors.file}</FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              {
                                props.Investments.data.cover
                                  ?   <img
                                    style={{height:'150px'}}
                                    src={`${process.env.REACT_APP_SERVER_URL}/${props.Investments.data.cover.upload.path}`}/>
                                  :null
                              }

                            </div>
                            <Label className="form-label">დამატებითი ფოტოსურათები</Label>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted uil uil-cloud-upload" />
                                    </div>
                                    <h4>დაამატეთ ფოტოსურათები.</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div className="dropzone-previews mt-3" id="file-previews">
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                            <hr />
                            <Label className="form-label">ჩატვირთული ფოტოსურათები</Label>
                            <div className="dropzone-previews mt-3" id="file-previews">
                              {props.Investments.data.images.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">

                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.upload.full_path}
                                            src={f.upload.full_path}
                                          />
                                        </Col>
                                        <Col>
                                          <strong
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.upload.name}
                                          </strong>
                                          <p className="mb-0">
                                            <strong
                                              onClick={() => handleDeleteFile(f.id, i)}
                                              style={{ color: "red", cursor: "pointer" }}>
                                              წაშლა
                                            </strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>


                          </Col>
                          <Col lg="6">
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label className="form-label">კომპანიის სახელი</Label>
                                  <Input
                                    name="company_name"
                                    className="form-control"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.company_name || ""}
                                    invalid={
                                      validation.touched.company_name && validation.errors.company_name ? true : false
                                    }
                                  />
                                  {validation.touched.company_name && validation.errors.company_name ? (
                                    <FormFeedback type="invalid">{validation.errors.company_name}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>

                          <Col>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                            >
                              განახლება
                            </button>
                          </Col>
                        </Row>


                      </Form>
                    </Col>
                    : null
                }


              </Row>

            </CardBody>
          </Card>

        </div>
      </div>
    </React.Fragment>

  )
}


const mapStateToProps = state => {
  const {
    Investments,
    Company
  } = state
  return {
    Investments,
    Company
  }
}
export default withRouter(connect(mapStateToProps)(Add))
