export const ADD_GRANTS_REQUEST = "ADD_GRANTS_REQUEST"
export const ADD_GRANTS_REQUEST_SUCCESS = "ADD_GRANTS_REQUEST_SUCCESS"

export const GET_GRANTS_REQUEST = "GET_GRANTS_REQUEST"
export const GET_GRANTS_REQUEST_SUCCESS = "GET_GRANTS_REQUEST_SUCCESS"

export const GET_GRANT_REQUEST = "GET_GRANT_REQUEST"
export const GET_GRANT_REQUEST_SUCCESS = "GET_GRANT_REQUEST_SUCCESS"

export const UPDATE_GRANT_REQUEST = "UPDATE_GRANT_REQUEST"
export const UPDATE_GRANT_REQUEST_SUCCESS = "UPDATE_GRANT_REQUEST_SUCCESS"

export const DELETE_GRANT_REQUEST = "DELETE_GRANT_REQUEST"
export const DELETE_GRANT_REQUEST_SUCCESS = "DELETE_GRANT_REQUEST_SUCCESS"

