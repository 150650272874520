import {
  ADD_COMPANY_REQUEST,
  GET_COMPANIES_REQUEST,
  GET_COMPANY_REQUEST,
  UPDATE_COMPANY_REQUEST,
  DELETE_COMPANY_REQUEST, SEARCH_COMPANY_REQUEST, SEARCH_COMPANY_NULLED_REQUEST
} from "./actionTypes"

export const addCompany = (data) => ({
  type: ADD_COMPANY_REQUEST,
  payload :data
})

export const getCompanies = (data) => ({
  type: GET_COMPANIES_REQUEST,
  payload :data
})

export const getCompany = (data) => ({
  type: GET_COMPANY_REQUEST,
  payload :data
})

export const updateCompany = (data, id) => ({
  type: UPDATE_COMPANY_REQUEST,
  payload :{data,id}
})

export const deleteCompany = (data) => ({
  type: DELETE_COMPANY_REQUEST,
  payload :data
})


export const searchCompany = (data) => ({
  type: SEARCH_COMPANY_REQUEST,
  payload :data
})

export const searchCompanyNulled = () => ({
  type: SEARCH_COMPANY_NULLED_REQUEST,
})
