import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_GRANTS_REQUEST,
  ADD_GRANTS_REQUEST_SUCCESS,
  GET_GRANTS_REQUEST,
  UPDATE_GRANT_REQUEST,
  DELETE_GRANT_REQUEST,
  GET_GRANTS_REQUEST_SUCCESS,
  GET_GRANT_REQUEST,
  GET_GRANT_REQUEST_SUCCESS,
  DELETE_GRANT_REQUEST_SUCCESS
} from "./actionTypes"
import {
  successAddNewPost,
  errorAddPost, successUpdatePost
} from "../../components/Notifications/Resp"



function* addGrantSaga({ payload }) {
  try {
    const callBack = yield call(addGrantSagaAsync, payload)
    if (callBack.success) {
      yield put({type: ADD_GRANTS_REQUEST_SUCCESS, callBack})
      successAddNewPost('success')
    } else {
      errorAddPost('error')
    }


  } catch (err) {
    console.error(err)
  }
}


const addGrantSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/grants`, {
    method: "POST",
    contentType:false,
    processData: false,
    enctype:'multipart/form-data',
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item
  })
  return await response.json()
}



function* getGrantsSaga({ payload }) {
  try {
    const callBack = yield call(getGrantsSagaAsync, payload)
    if (callBack.success) {
      yield put({type: GET_GRANTS_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}


const getGrantsSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/grants?data_per_page=${item.limit}&page=${item.page}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}



function* deleteGrantSaga({ payload }) {
  try {
    const callBack = yield call(deleteGrantSagaAsync, payload)
    if (callBack.status === 204) {
      yield put({type: DELETE_GRANT_REQUEST_SUCCESS, payload})
    }
  } catch (err) {
    console.error(err)
  }
}


const deleteGrantSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/grants/${item.id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response
}



function* getGrantSaga({ payload }) {
  try {
    const callBack = yield call(getGrantSagaAsync, payload)
    if (callBack.success) {
      yield put({type: GET_GRANT_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}


const getGrantSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/grants/${item.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}



function* updateGrantSaga({ payload }) {
  try {
    const callBack = yield call(updateGrantSagaAsync, payload)
    if (callBack.success) {
      successUpdatePost('success')
    }else {
      errorAddPost('error')
    }
  } catch (err) {
    console.error(err)
  }
}


const updateGrantSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/grants/${item.id}?_method=PUT`, {
    method: "POST",
    contentType:false,
    processData: false,
    enctype:'multipart/form-data',
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item.data
  })
  return await response.json()
}





function* authSaga() {
  yield takeEvery(ADD_GRANTS_REQUEST, addGrantSaga)
  yield takeEvery(GET_GRANTS_REQUEST, getGrantsSaga)
  yield takeEvery(GET_GRANT_REQUEST, getGrantSaga)
  yield takeEvery(UPDATE_GRANT_REQUEST, updateGrantSaga)
  yield takeEvery(DELETE_GRANT_REQUEST, deleteGrantSaga)
}

export default authSaga
