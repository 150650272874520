import {
  GET_PAGE_REQUEST_SUCCESS, UPDATE_PAGE_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  Error: null,
  updateResultEdit: false,
  data: {
    meta_title: '',
    meta_description: '',
    meta_keywords: '',
    file: '',
  }
}

const Pages = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_PAGE_REQUEST_SUCCESS:
      state.data.meta_title = action.callBack.result.data[action.payload.id].meta_title.content
      state.data.meta_description = action.callBack.result.data[action.payload.id].meta_description.content
      state.data.meta_keywords = action.callBack.result.data[action.payload.id].meta_keywords.content
      state.data.file = action.callBack.result.data[action.payload.id].cover.content
      return state = {
        ...state,
      }

    case UPDATE_PAGE_REQUEST_SUCCESS:
      return state = {
        ...state,
        updateResultEdit: !state.updateResultEdit
      }
    default:
      return state
  }
}

export default Pages
