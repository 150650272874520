import {
  GET_DASHBOARD_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  Error: null,
  result: {
    "blog": 1,
    "company_service": 12,
    "offer": 8,
    "grant": 5,
    "required_link": 3,
    "investment": 3,
    "company": 6,
    "user": 11,
    "company_review": 1
  },

}

const Company = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_REQUEST_SUCCESS:
      return state = {
        ...state,
        result: action.callBack.result
      }

    default:
      return state
  }
}

export default Company
