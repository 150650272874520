import {
  ADD_FILTR_REQUEST_SUCCESS,
  GET_FILTRES_REQUEST_SUCCESS,
  DELETE_FILTR_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  Error: null,
  result: {
    data:[],
    total:0
  },
}

const Filters = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_FILTR_REQUEST_SUCCESS:
      let array = state.result.data
      let tr = action.callBack.result.data
      array.push(tr)
      return state = {
        ...state,
      }
    case GET_FILTRES_REQUEST_SUCCESS:

      return state = {
        ...state,
        result: action.callBack.result
      }

    case DELETE_FILTR_REQUEST_SUCCESS:
      state.result.data =  state.result.data.filter(todo => todo.id !== action.payload.id)
      return state = {
        ...state,
      }

    default:
      return state
  }
}

export default Filters
