import {
  ADD_SERVICE_REQUEST,
  GET_SERVICES_REQUEST,
  GET_SERVICE_REQUEST,
  UPDATE_SERVICE_REQUEST,
  DELETE_SERVICE_REQUEST
} from "./actionTypes"

export const addService = (data, type) => ({
  type: ADD_SERVICE_REQUEST,
  payload :{data,type}
})

export const getServices = (data) => ({
  type: GET_SERVICES_REQUEST,
  payload :data
})

export const getService = (data) => ({
  type: GET_SERVICE_REQUEST,
  payload :data
})

export const updateService = (data, id) => ({
  type: UPDATE_SERVICE_REQUEST,
  payload :{data,id}
})

export const deleteService = (data) => ({
  type: DELETE_SERVICE_REQUEST,
  payload :data
})
