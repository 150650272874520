import { call, put, takeEvery } from "redux-saga/effects"

import {
  DELETE_FILE_REQUEST
} from "./actionTypes"

import {
  successDeleteFile
} from "../../components/Notifications/Resp"
import { DELETE_FILE_REQUEST_SERVICES_SUCCESS } from "../Services/actionTypes"
import { DELETE_FILE_REQUEST_INVESTMENT_SUCCESS } from "../Investments/actionTypes"
import { DELETE_FILE_REQUEST_POST_SUCCESS } from "../Post/actionTypes"
import { DELETE_FILE_REQUEST_REQUIRED_LINK_SUCCESS } from "../RequiredLinks/actionTypes"

function* deleteFileSaga({ payload }) {
  try {
    const callBack = yield call(deleteFileSagaAsync, payload)
    if (callBack.status === 204) {
      successDeleteFile("success")
      if (payload.type === "services") {
        yield put({ type: DELETE_FILE_REQUEST_SERVICES_SUCCESS, payload })
      }
      if (payload.type === "investments") {
        yield put({ type: DELETE_FILE_REQUEST_INVESTMENT_SUCCESS, payload })
      }
      if (payload.type === "blogs") {
        yield put({ type: DELETE_FILE_REQUEST_POST_SUCCESS, payload })
      }
      if (payload.type === "requiredLinks") {
        yield put({ type: DELETE_FILE_REQUEST_REQUIRED_LINK_SUCCESS, payload })
      }
    }
  } catch (err) {
    console.error(err)
  }
}

const deleteFileSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/upload-nodes/${item.id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response

}


function* authSaga() {
  yield takeEvery(DELETE_FILE_REQUEST, deleteFileSaga)
}

export default authSaga
