import {
  ADD_POST_REQUEST,
  GET_POSTS_REQUEST,
  GET_POST_REQUEST,
  UPDATE_POST_REQUEST,
  DELETE_POST_REQUEST
} from "./actionTypes"

export const addPost = (data) => ({
  type: ADD_POST_REQUEST,
  payload :data
})

export const getPosts = (data) => ({
  type: GET_POSTS_REQUEST,
  payload :data
})

export const getPost = (data) => ({
  type: GET_POST_REQUEST,
  payload :data
})

export const updatePost = (data, id) => ({
  type: UPDATE_POST_REQUEST,
  payload :{data,id}
})

export const deletePost = (data) => ({
  type: DELETE_POST_REQUEST,
  payload :data
})
