import {
  GET_PAGE_REQUEST, UPDATE_PAGE_REQUEST
} from "./actionTypes"


export const getPage = (data) => ({
  type: GET_PAGE_REQUEST,
  payload :data
})

export const updatePage = (data) => ({
  type: UPDATE_PAGE_REQUEST,
  payload :{data}
})

