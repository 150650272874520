import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_SERVICE_REQUEST,
  ADD_SERVICE_REQUEST_SUCCESS,
  GET_SERVICES_REQUEST,
  GET_SERVICE_REQUEST,
  UPDATE_SERVICE_REQUEST,
  DELETE_SERVICE_REQUEST,
  GET_SERVICES_REQUEST_SUCCESS,
  DELETE_SERVICE_REQUEST_SUCCESS,
  GET_SERVICE_REQUEST_SUCCESS,
  UPDATE_SERVICE_REQUEST_SUCCESS
} from "./actionTypes"
import {
  successAddNewPost,
  errorAddPost, successUpdatePost
} from "../../components/Notifications/Resp"

function* addServiceSaga({ payload }) {
  try {
    const callBack = yield call(addServiceSagaAsync, payload)
    if (callBack.success) {
      yield put({type: ADD_SERVICE_REQUEST_SUCCESS})
      successAddNewPost('success')
    } else {
      errorAddPost('error')
    }
  } catch (err) {
    console.error(err)
  }
}

const addServiceSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/company-services?type_id=${item.type}`, {
    method: "POST",
    contentType:false,
    processData: false,
    enctype:'multipart/form-data',
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item.data
  })
  return await response.json()
}



function* getServicesSaga({ payload }) {
  try {
    const callBack = yield call(getServicesSagaAsync, payload)
    if (callBack.success) {
      yield put({type: GET_SERVICES_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}

const getServicesSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/company-services?data_per_page=${item.limit}&page=${item.page}&type_id=${item.type}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}

function* getServiceSaga({ payload }) {
  try {
    const callBack = yield call(getServiceSagaAsync, payload)
    if (callBack.success) {
      yield put({type: GET_SERVICE_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}


const getServiceSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/company-services/${item.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}



function* deleteServiceSaga({ payload }) {
  try {
    const callBack = yield call(deleteServiceSagaAsync, payload)
    if (callBack.status === 204) {
      yield put({type: DELETE_SERVICE_REQUEST_SUCCESS, payload})
    }
  } catch (err) {
    console.error(err)
  }
}


const deleteServiceSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/company-services/${item.id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response
}



function* updateServiceSaga({ payload }) {
  try {
    const callBack = yield call(updateServiceSagaAsync, payload)
    if (callBack.success) {
      successUpdatePost('success')
      yield put({type: UPDATE_SERVICE_REQUEST_SUCCESS, payload})
    } else {
      errorAddPost('error')
    }
  } catch (err) {
    console.error(err)
  }
}

const updateServiceSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/company-services/${item.id}?_method=PUT`, {
    method: "POST",
    contentType:false,
    processData: false,
    enctype:'multipart/form-data',
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item.data
  })
  return await response.json()
}



function* authSaga() {
  yield takeEvery(ADD_SERVICE_REQUEST, addServiceSaga)
  yield takeEvery(GET_SERVICES_REQUEST, getServicesSaga)
  yield takeEvery(DELETE_SERVICE_REQUEST, deleteServiceSaga)
  yield takeEvery(GET_SERVICE_REQUEST, getServiceSaga)
  yield takeEvery(UPDATE_SERVICE_REQUEST, updateServiceSaga)
}

export default authSaga
