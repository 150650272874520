import {
  ADD_FILTR_REQUEST, DELETE_FILTR_REQUEST, GET_FILTRES_REQUEST
} from "./actionTypes"

export const addFiltre = (data) => ({
  type: ADD_FILTR_REQUEST,
  payload :data
})

export const getFilters = (data) => ({
  type: GET_FILTRES_REQUEST,
  payload :data
})


export const deleteFilter = (data) => ({
  type: DELETE_FILTR_REQUEST,
  payload :data
})


