import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_REQUEST_SUCCESS,
  GET_COMPANIES_REQUEST,
  GET_COMPANY_REQUEST,
  UPDATE_COMPANY_REQUEST,
  DELETE_COMPANY_REQUEST,
  GET_COMPANIES_REQUEST_SUCCESS,
  DELETE_COMPANY_REQUEST_SUCCESS,
  GET_COMPANY_REQUEST_SUCCESS,
  SEARCH_COMPANY_REQUEST, SEARCH_COMPANY_REQUEST_SUCCESS, UPDATE_COMPANY_REQUEST_SUCCESS
} from "./actionTypes"
import {
  successAddNewPost,
  errorAddPost, successUpdatePost, errorNotFoundCompany, varningNotFoundCompany
} from "../../components/Notifications/Resp"




function* addCompanySaga({ payload }) {
  try {
    const callBack = yield call(addCompanySagaAsync, payload)
    if (callBack.success) {
      yield put({type: ADD_COMPANY_REQUEST_SUCCESS, callBack})
      successAddNewPost('success')
    } else {
      errorAddPost('error')
    }

  } catch (err) {
    console.error(err)
  }
}

//

const addCompanySagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/companies`, {
    method: "POST",
    contentType:false,
    processData: false,
    enctype:'multipart/form-data',
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item
  })
  return await response.json()
}



function* getCompaniesSaga({ payload }) {
  try {
    const callBack = yield call(getCompaniesSagaAsync, payload)
    if (callBack.success) {
      yield put({type: GET_COMPANIES_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}


const getCompaniesSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/companies?data_per_page=${item.limit}&page=${item.page}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}

function* getCompanySaga({ payload }) {
  try {
    const callBack = yield call(getCompanySagaAsync, payload)
    if (callBack.success) {
      yield put({type: GET_COMPANY_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}


const getCompanySagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/companies/${item.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}



function* deleteCompanySaga({ payload }) {
  try {
    const callBack = yield call(deleteCompanySagaAsync, payload)
    if (callBack.status === 204) {
      yield put({type: DELETE_COMPANY_REQUEST_SUCCESS, payload})
    }
  } catch (err) {
    console.error(err)
  }
}


const deleteCompanySagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/companies/${item.id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response
}



function* updateCompanySaga({ payload }) {
  try {
    const callBack = yield call(updateCompanySagaAsync, payload)
    if (callBack.success) {
      yield put({type: UPDATE_COMPANY_REQUEST_SUCCESS, payload})
      successUpdatePost('success')
    } else {
      errorAddPost('error')
    }
  } catch (err) {
    console.error(err)
  }
}

const updateCompanySagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/companies/${item.id}?_method=PUT`, {
    method: "POST",
    contentType:false,
    processData: false,
    enctype:'multipart/form-data',
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item.data
  })
  return await response.json()
}

function* searchCompanySaga({ payload }) {
  try {
    const callBack = yield call(searchCompanySagaAsync, payload)
    if (callBack.success) {
      if (callBack.result.data.length >0) {
        yield put({type: SEARCH_COMPANY_REQUEST_SUCCESS, callBack})
      }else {
        varningNotFoundCompany('warning')
      }
    } else {
      varningNotFoundCompany('warning')
    }
  } catch (err) {
    console.error(err)
  }
}

const searchCompanySagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/companies?search=${item.search}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}

function* authSaga() {
  yield takeEvery(ADD_COMPANY_REQUEST, addCompanySaga)
  yield takeEvery(GET_COMPANIES_REQUEST, getCompaniesSaga)
  yield takeEvery(DELETE_COMPANY_REQUEST, deleteCompanySaga)
  yield takeEvery(GET_COMPANY_REQUEST, getCompanySaga)
  yield takeEvery(UPDATE_COMPANY_REQUEST, updateCompanySaga)
  yield takeEvery(SEARCH_COMPANY_REQUEST, searchCompanySaga)
}

export default authSaga
