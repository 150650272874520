export const ADD_INVESTMENT_REQUEST = "ADD_INVESTMENT_REQUEST"
export const ADD_INVESTMENT_REQUEST_SUCCESS = "ADD_INVESTMENT_REQUEST_SUCCESS"

export const GET_INVESTMENTS_REQUEST = "GET_INVESTMENTS_REQUEST"
export const GET_INVESTMENTS_REQUEST_SUCCESS = "GET_INVESTMENTS_REQUEST_SUCCESS"

export const GET_INVESTMENT_REQUEST = "GET_INVESTMENT_REQUEST"
export const GET_INVESTMENT_REQUEST_SUCCESS = "GET_INVESTMENT_REQUEST_SUCCESS"

export const UPDATE_INVESTMENT_REQUEST = "UPDATE_INVESTMENT_REQUEST"
export const UPDATE_INVESTMENT_REQUEST_SUCCESS = "UPDATE_INVESTMENT_REQUEST_SUCCESS"

export const DELETE_INVESTMENT_REQUEST = "DELETE_INVESTMENT_REQUEST"
export const DELETE_INVESTMENT_REQUEST_SUCCESS = "DELETE_INVESTMENT_REQUEST_SUCCESS"

export const DELETE_FILE_REQUEST_INVESTMENT_SUCCESS = "DELETE_FILE_REQUEST_INVESTMENT_SUCCESS"

export const GET_FILTRES_INDUSTRY_REQUEST_SUCCESS = "GET_FILTRES_INDUSTRY_REQUEST_SUCCESS"
export const GET_FILTRES_LOCATION_REQUEST_SUCCESS = "GET_FILTRES_LOCATION_REQUEST_SUCCESS"
