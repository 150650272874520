import {
  CHECK_ADMIN_STATUS_REQUEST_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  isAuthenticated: null,
  admin: {},
  registrationError: null,
  registrationLoading: false
}

const Authorisation = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHECK_ADMIN_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        admin: action.callBack.result.data
      }

    default:
      return state
  }
}

export default Authorisation
