import React, { useEffect, useState, useRef } from "react"
import { connect, useDispatch } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { useFormik } from "formik"
import * as Yup from "yup"
import { addService, getService } from "../../store/Services/actions"
import Dropzone from "react-dropzone"
import { addPost, getPost, updatePost } from "../../store/Post/actions"
import { deleteFile } from "../../store/Photos/actions"
import { getReview, updateReview } from "../../store/Review/actions"

const Edit = props => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    if (props.match.params.id) {
      let item = {
        id: props.match.params.id
      }
      dispatch(getReview(item))
    }
  }, [props.match.params.id])


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: props.Review.data.id || "",
      comment: props.Review.data.comment || "",
      type_id: props.Review.data.type_id || "",
      company_id: props.Review.data.company_id || "",
      user_id: props.Review.data.user_id || "",
      points: props.Review.data.points || "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values, { resetForm }) => {

      let sendData = new FormData()
      sendData.append("id", values.id)
      sendData.append("comment", values.comment)
      sendData.append("type_id", values.type_id)
      sendData.append("company_id", values.company_id)
      sendData.append("user_id", values.user_id)
      sendData.append("points", values.points)

      dispatch(updateReview(sendData, props.match.params.id))

    }
  })

  console.log("CCCC", props.Review.data.type_id)


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="სია" breadcrumbItem="სიახლე" />
          <Card>
            <CardBody>
              <Row>
                {
                  props.Review.data.id
                    ?
                    <Col lg={12}>
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >

                        <Row>

                          <Col lg="8">
                            <div className="mb-3">
                              <Label className="form-label">მცირე აღწერილობა</Label>
                              <Input
                                name="comment"
                                className="form-control"
                                type="textarea"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.comment || ""}
                                invalid={
                                  validation.touched.comment && validation.errors.comment ? true : false
                                }
                              />
                              {validation.touched.comment && validation.errors.comment ? (
                                <FormFeedback type="invalid">{validation.errors.comment}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">სტატუსი</Label>
                              <select
                                name={"type_id"}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.type_id || ""}
                                className="form-select">
                                <option>აირჩიერთ ერთ-ერთი</option>
                                <option value={1}>განხილვა</option>
                                <option value={2}>დადასტურება</option>
                                <option value={3}>უარყოფ</option>
                              </select>
                            </div>
                          </Col>


                        </Row>
                        <Row>
                          <Col className={"pt-3"}>

                          </Col>
                        </Row>
                        <Row>

                          <Col>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                            >
                              განახლება
                            </button>
                          </Col>
                        </Row>


                      </Form>
                    </Col>
                    : null

                }
              </Row>


            </CardBody>
          </Card>

        </div>
      </div>
    </React.Fragment>

  )
}

const mapStateToProps = state => {
  const {
    Review
  } = state
  return {
    Review
  }
}
export default withRouter(connect(mapStateToProps)(Edit))

