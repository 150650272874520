export const ADD_REQUIRED_LINK_REQUEST = "ADD_REQUIRED_LINK_REQUEST"
export const ADD_REQUIRED_LINK_REQUEST_SUCCESS = "ADD_REQUIRED_LINK_REQUEST_SUCCESS"

export const GET_REQUIRED_LINKS_REQUEST = "GET_REQUIRED_LINKS_REQUEST"
export const GET_REQUIRED_LINKS_REQUEST_SUCCESS = "GET_REQUIRED_LINKS_REQUEST_SUCCESS"

export const GET_REQUIRED_LINK_REQUEST = "GET_REQUIRED_LINK_REQUEST"
export const GET_REQUIRED_LINK_REQUEST_SUCCESS = "GET_REQUIRED_LINK_REQUEST_SUCCESS"

export const UPDATE_REQUIRED_LINK_REQUEST = "UPDATE_REQUIRED_LINK_REQUEST"
export const UPDATE_REQUIRED_LINK_REQUEST_SUCCESS = "UPDATE_REQUIRED_LINK_REQUEST_SUCCESS"

export const DELETE_REQUIRED_LINK_REQUEST = "DELETE_REQUIRED_LINK_REQUEST"
export const DELETE_REQUIRED_LINK_REQUEST_SUCCESS = "DELETE_REQUIRED_LINK_REQUEST_SUCCESS"

export const DELETE_FILE_REQUEST_REQUIRED_LINK_SUCCESS = "DELETE_FILE_REQUEST_REQUIRED_LINK_SUCCESS"

