import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_PAGE_REQUEST, GET_PAGE_REQUEST_SUCCESS, UPDATE_PAGE_REQUEST, UPDATE_PAGE_REQUEST_SUCCESS
} from "./actionTypes"
import {
  successAddNewPost,
  errorAddPost, successUpdatePost
} from "../../components/Notifications/Resp"



function* getPageSaga({ payload }) {
  try {
    const callBack = yield call(getPageSagaAsync, payload)
    if (callBack.success) {
      yield put({type: GET_PAGE_REQUEST_SUCCESS, callBack, payload})
    }
  } catch (err) {
    console.error(err)
  }
}

//http://68.183.241.43/api/static-contents?lang_id=1&type_id=1&page=services
const getPageSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/static-contents?lang_id=1&type_id=1&page=${item.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}


function* updatePageSaga({ payload }) {
  try {
    const callBack = yield call(updatePageSagaAsync, payload)
    if (callBack.success) {
      successUpdatePost('success')
      yield put({type: UPDATE_PAGE_REQUEST_SUCCESS, callBack, payload})
    } else {
      errorAddPost('error')
    }
  } catch (err) {
    console.error(err)
  }
}


const updatePageSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/static-contents/1?_method=PUT`, {
    method: "POST",
    contentType:false,
    processData: false,
    enctype:'multipart/form-data',
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item.data
  })
  return await response.json()
}



function* authSaga() {
  yield takeEvery(GET_PAGE_REQUEST, getPageSaga)
  yield takeEvery(UPDATE_PAGE_REQUEST, updatePageSaga)
}

export default authSaga
