import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { Row, Card, Col, Modal, FormGroup, Label, Button } from "reactstrap"
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator"
import { Link, withRouter } from "react-router-dom"
import FeatherIcon from "feather-icons-react"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"

import SweetAlert from "react-bootstrap-sweetalert"
import Nulled from "../../components/Null/Nulled"
import { deleteService } from "../../store/Services/actions"
import { deleteRequiredLink } from "../../store/RequiredLinks/actions"


const ObjectColumns = props => {
  const dispatch = useDispatch()
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [key, setKey] = useState({})
  const [id, setId] = useState({})



  const handleOpenAlertModal = (id, key) => {
    setKey(key)
    setId(id)
    setconfirm_alert(true)
  }


  const handelOpenEditPage = () =>{

  }
  const handleRemove = () => {
    let item = {
      key,
      id
    }
    dispatch(deleteRequiredLink(item))
    setconfirm_alert(false)
  }


  const paginationOption = {
    sizePerPage: process.env.REACT_APP_LIST_LIMIT,
    totalSize: props.dataTotal, // replace later with size(customerList),
    hideSizePerPage: true,
    alwaysShowAllBtns: false,

    nextPageText: "შემდეგი",
    prePageText: "წინ",
    onPageChange: function(page, sizePerPage) {
      props.pagination(page)
    }
  }

  const Columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
    },
    {
      dataField: "title",
      text: "სათაური",
      sort: true,
    },
    {
      dataField: "sub_title",
      text: "ქვე სათაური",
      sort: true,
    },


    {
      dataField: "row.id",
      text: "",
      sort: true,
      formatter: (cellContent, row, key) => (
        <div key={row.id}>
          <span style={{ cursor: "pointer" }} className="px-3 text-primary">
              <Link to={`/edit-requiredLink/${row.id}`}>
                    <FeatherIcon icon="edit" className="icon-xs icon me-2 text-pending" />
              </Link>
            </span>
          <span
            onClick={() => {
              handleOpenAlertModal(row.id, key)
            }}
            style={{ cursor: "pointer" }} className="px-3 text-primary">
              <FeatherIcon icon="trash" className="icon-xs icon me-2 text-danger" />
            </span>
        </div>
      )
    }
  ]
  return (
    <>
      {
        !isEmpty(props.data)
          ?
          <>
            <PaginationProvider
              pagination={paginationFactory(paginationOption)}
            >
              {
                ({
                   paginationProps,
                   paginationTableProps
                 }) => (
                  <Row>
                      <BootstrapTable
                        keyField="id"
                        data={props.data}
                        columns={Columns}
                        {...paginationTableProps}
                      />

                     <div className="float-sm-end">
                      <PaginationListStandalone
                        {...paginationProps}
                      />
                    </div>
                  </Row>
                )
              }
            </PaginationProvider>
          </>
          : <Nulled />
      }

      <Col xl={3} lg={4} sm={6} className="mb-2">
        {confirm_alert ? (
          <SweetAlert
            title="დარწმუნებული ხართ?"
            warning
            showCancel
            confirmButtonText="Yes, delete it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={handleRemove}
            onCancel={() => setconfirm_alert(false)}
          >
            რომ გსურთ წაშალოთ?!
          </SweetAlert>
        ) : null}
      </Col>
    </>
  )
}

const mapStateToProps = state => {
  const {
    Users
  } = state
  return {
    Users
  }
}
export default withRouter(connect(mapStateToProps)(ObjectColumns))
