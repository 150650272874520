import React, { useEffect, useState, useRef } from "react"
import { connect, useDispatch } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { useFormik } from "formik"
import * as Yup from "yup"
import { addService, getService } from "../../store/Services/actions"
import Dropzone from "react-dropzone"
import { addPost, getPost, updatePost } from "../../store/Post/actions"
import { deleteFile } from "../../store/Photos/actions"

const Edit = props => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const inputRef = useRef(null)
  const [text, setText] = useState(EditorState.createEmpty())
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFiles, setselectedFiles] = useState([])

  const onEditorText = editorState => {
    setText(editorState)
  }


  useEffect(() => {
    setselectedFiles([])
    if (isLoading) {
      if (props.match.params.id) {
        let item = {
          id: props.match.params.id
        }
        dispatch(getPost(item))
      }
    }
  }, [props.Post.updateResultEdit])

  useEffect(() => {
    if (props.match.params.id) {
      let item = {
        id: props.match.params.id
      }
      dispatch(getPost(item))
    }
  }, [props.match.params.id ])
  useEffect(() => {
    setIsLoading(true)
    setText(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(props.Post.data.text)
        ))
    )
  }, [ props.Post.data.text])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: props.Post.data.id || "",
      title: props.Post.data.title || "",
      description: props.Post.data.description || "",
      meta_title: props.Post.data.meta_title || "",
      meta_description: props.Post.data.meta_description || "",
      meta_keywords: props.Post.data.meta_keywords || ""
    },
    validationSchema: Yup.object({
      title: Yup.string().required("შეიყვანეთ სათაური"),
      description: Yup.string().required("შეიყვანეთ მცირე აღწერილობა").max(250, 'აღწერილობა უნდა იყოს მაქსიმუმ 250 სიმბოლო'),
    }),
    onSubmit: (values, { resetForm }) => {

      let sendData = new FormData()
      sendData.append("title", values.title)
      sendData.append("meta_title", values.meta_title === "undefined" ? "" : values.meta_title)
      sendData.append("meta_description", values.meta_description === "undefined" ? "" : values.meta_description)
      sendData.append("meta_keywords", values.meta_keywords === "undefined" ? "" : values.meta_keywords)
      sendData.append("description", values.description)
      sendData.append("text", draftToHtml(convertToRaw(text.getCurrentContent())))
      sendData.append("file", selectedFile)

      selectedFiles.forEach((item) => {
        sendData.append("images[]", item)
      })

      dispatch(updatePost(sendData, props.match.params.id))

    }
  })

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleDeleteFile = (f, i) => {
    let item = {
      id: f,
      key: i,
      type: "blogs"
    }
    dispatch(deleteFile(item))
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )
    setselectedFiles(files)
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="სია" breadcrumbItem="სიახლე" />
          <Card>
            <CardBody>
              <Row>
                {
                  props.Post.data.id
                    ?
                    <Col lg={12}>
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >

                        <Row>
                          <Col lg="12">
                            <div className="mb-3">
                              <Label className="form-label">სათაური</Label>
                              <Input
                                name="title"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title && validation.errors.title ? true : false
                                }
                              />
                              {validation.touched.title && validation.errors.title ? (
                                <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">თეგი Title</Label>
                              <Input
                                name="meta_title"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.meta_title || ""}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">თეგი Description</Label>
                              <Input
                                name="meta_description"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.meta_description || ""}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">თეგი Keywords</Label>
                              <Input
                                name="meta_keywords"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.meta_keywords || ""}
                              />

                            </div>
                          </Col>

                          <Col lg="12">
                            <div className="mb-3">
                              <Label className="form-label">მცირე აღწერილობა</Label>
                              <Input
                                name="description"
                                className="form-control"
                                type="textarea"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description && validation.errors.description ? true : false
                                }
                              />
                              {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg="12">
                            <div className="mb-3">
                              <Label className="form-label">ტექსტი</Label>
                              <Editor
                                editorState={text}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                onEditorStateChange={onEditorText}
                              />
                            </div>
                          </Col>


                          <Col lg="12">
                            <h3>ფოტოსურათები</h3>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="form-label">მთავარი ფოტოსურათი</Label>
                              <input ref={inputRef} type="file"
                                     className="form-control"
                                     onChange={(e) => setSelectedFile(e.target.files[0])}
                              />
                              {validation.touched.file && validation.errors.file ? (
                                <FormFeedback type="invalid">{validation.errors.file}</FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              {
                                props.Post.data.cover
                                ? <img
                                    style={{ height: "150px" }}
                                    src={`${process.env.REACT_APP_SERVER_URL}/${props.Post.data.cover.upload.path}`} />
                                  :null
                              }

                            </div>
                          </Col>
                          <Col lg="6">
                            <Label className="form-label">დამატებითი ფოტოსურათები</Label>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted uil uil-cloud-upload" />
                                    </div>
                                    <h4>დაამატეთ ფოტოსურათები.</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div className="dropzone-previews mt-3" id="file-previews">
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                            <hr />
                            <Label className="form-label">ჩატვირთული ფოტოსურათები</Label>
                            <div className="dropzone-previews mt-3" id="file-previews">
                              {props.Post.data.images.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">

                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.upload.full_path}
                                            src={f.upload.full_path}
                                          />
                                        </Col>
                                        <Col>
                                          <strong
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.upload.name}
                                          </strong>
                                          <p className="mb-0">
                                            <strong
                                              onClick={() => handleDeleteFile(f.id, i)}
                                              style={{ color: "red", cursor: "pointer" }}>
                                              წაშლა
                                            </strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>

                          </Col>


                        </Row>
                        <Row>
                          <Col className={"pt-3"}>

                          </Col>
                        </Row>
                        <Row>

                          <Col>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                            >
                              განახლება
                            </button>
                          </Col>
                        </Row>


                      </Form>
                    </Col>
                    : null

                }
              </Row>


            </CardBody>
          </Card>

        </div>
      </div>
    </React.Fragment>

  )
}

const mapStateToProps = state => {
  const {
    Post
  } = state
  return {
    Post
  }
}
export default withRouter(connect(mapStateToProps)(Edit))

