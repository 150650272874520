import {
  ADD_GRANTS_REQUEST,
  GET_GRANTS_REQUEST,
  GET_GRANT_REQUEST,
  UPDATE_GRANT_REQUEST,
  DELETE_GRANT_REQUEST
} from "./actionTypes"

export const addGrant = (data) => ({
  type: ADD_GRANTS_REQUEST,
  payload :data
})

export const getGrants = (data) => ({
  type: GET_GRANTS_REQUEST,
  payload :data
})

export const getGrant = (data) => ({
  type: GET_GRANT_REQUEST,
  payload :data
})

export const updateGrant = (data, id) => ({
  type: UPDATE_GRANT_REQUEST,
  payload :{data,id}
})

export const deleteGrant = (data) => ({
  type: DELETE_GRANT_REQUEST,
  payload :data
})
