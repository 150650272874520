import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_POST_REQUEST,
  ADD_POST_REQUEST_SUCCESS,
  GET_POSTS_REQUEST,
  GET_POST_REQUEST,
  UPDATE_POST_REQUEST,
  DELETE_POST_REQUEST,
  GET_POSTS_REQUEST_SUCCESS,
  DELETE_POST_REQUEST_SUCCESS,
  GET_POST_REQUEST_SUCCESS,
  UPDATE_POST_REQUEST_SUCCESS
} from "./actionTypes"
import {
  successAddNewPost,
  errorAddPost, successUpdatePost
} from "../../components/Notifications/Resp"




function* addPostSaga({ payload }) {
  try {
    const callBack = yield call(addPostSagaAsync, payload)
    if (callBack.success) {
      yield put({type: ADD_POST_REQUEST_SUCCESS, callBack})
      successAddNewPost('success')
    } else {
      errorAddPost('error')
    }

  } catch (err) {
    console.error(err)
  }
}

//

const addPostSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/blogs`, {
    method: "POST",
    contentType:false,
    processData: false,
    enctype:'multipart/form-data',
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item
  })
  return await response.json()
}



function* getPostsSaga({ payload }) {
  try {
    const callBack = yield call(getPostsSagaAsync, payload)

    if (callBack.success) {
      yield put({type: GET_POSTS_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}

const getPostsSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/blogs?data_per_page=${item.limit}&page=${item.page}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}


function* getPostSaga({ payload }) {
  try {
    const callBack = yield call(getPostSagaAsync, payload)

    if (callBack.success) {
      yield put({type: GET_POST_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}


const getPostSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/blogs/${item.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}



function* deletePostSaga({ payload }) {
  try {
    const callBack = yield call(deletePostSagaAsync, payload)
    if (callBack.status === 204) {
      yield put({type: DELETE_POST_REQUEST_SUCCESS, payload})
    }
  } catch (err) {
    console.error(err)
  }
}


const deletePostSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/blogs/${item.id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response
}



function* updatePostSaga({ payload }) {
  try {
    const callBack = yield call(updatePostSagaAsync, payload)

    if (callBack.success) {
      yield put({type: UPDATE_POST_REQUEST_SUCCESS, payload})

      successUpdatePost('success')
    } else {
      errorAddPost('error')
    }
  } catch (err) {
    console.error(err)
  }
}


const updatePostSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/blogs/${item.id}?_method=PUT`, {
    method: "POST",
    contentType:false,
    processData: false,
    enctype:'multipart/form-data',
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item.data
  })
  return await response.json()
}



function* authSaga() {
  yield takeEvery(ADD_POST_REQUEST, addPostSaga)
  yield takeEvery(GET_POSTS_REQUEST, getPostsSaga)
  yield takeEvery(GET_POST_REQUEST, getPostSaga)
  yield takeEvery(DELETE_POST_REQUEST, deletePostSaga)
  yield takeEvery(UPDATE_POST_REQUEST, updatePostSaga)
}

export default authSaga
