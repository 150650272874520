import {notification} from 'antd';
import {withTranslation} from "react-i18next";
import i18n from "../../i18n"


export const successAddNewPost = type => {
  notification[type]({
    message: 'წარმატება!',
    description: 'ინფორმაცია წარმატებით დაემატა',
  });
};

export const successDeleteFile = type => {
  notification[type]({
    message: 'წარმატება!',
    description: 'ფაილი წარმატებით წაიშალა',
  });
};

export const successUpdatePost = type => {
  notification[type]({
    message: 'წარმატება!',
    description: 'ინფორმაცია წარმატებით განახლდა',
  });
};

export const errorAddPost = type => {
  notification[type]({
    message: 'შეცდომა',
    description: 'მოხდა შეცდომა, გთხოვთ შეავსოთ ყველა ვერლი და ცადოთ ახლიდან',
  });
};
export const varningNotFoundCompany = type => {
  notification[type]({
    message: 'კომპანია ვერ მოიძებნა',
    description: '',
  });
};

export const errorNotFoundCompany = type => {
  notification[type]({
    message: 'შეცდომა',
    description: 'კომპანია ვერ მოიძებნა',
  });
};


/*
export const successStatusUpdate = type => {
  notification[type]({
    message: 'წარმატება',
    description: 'სტატუსი წარმატებით განახლდა',
  });
};

export const successAddPhotos = type => {
  notification[type]({
    message: 'წარმატება',
    description: 'ფოტოები წარმატებით დაემატა',
  });
};

export const errorAddPhotosNullPhotos = type => {
  notification[type]({
    message: 'შეცდომა',
    description: 'გთხოვთ ატვირთოთ ფოტოსურათები',
  });
};
*/
