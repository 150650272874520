import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_REVIEW_REQUEST,
  ADD_REVIEW_REQUEST_SUCCESS,
  GET_REVIEWS_REQUEST,
  GET_REVIEW_REQUEST,
  UPDATE_REVIEW_REQUEST,
  DELETE_REVIEW_REQUEST,
  GET_REVIEWS_REQUEST_SUCCESS,
  DELETE_REVIEW_REQUEST_SUCCESS,
  GET_REVIEW_REQUEST_SUCCESS,
  UPDATE_REVIEW_REQUEST_SUCCESS
} from "./actionTypes"

import {
  errorAddPost, successUpdatePost
} from "../../components/Notifications/Resp"


function* getReviwsSaga({ payload }) {
  try {
    const callBack = yield call(getReviwsSagaAsync, payload)
    if (callBack.success) {
      yield put({type: GET_REVIEWS_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}

const getReviwsSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/company-reviews?data_per_page=${item.limit}&page=${item.page}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}

function* getReviewSaga({ payload }) {
  try {
    const callBack = yield call(getReviewSagaAsync, payload)
    if (callBack.success) {
      yield put({type: GET_REVIEW_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}


const getReviewSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/company-reviews/${item.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}



function* deleteReviewSaga({ payload }) {
  try {
    const callBack = yield call(deleteReviewSagaAsync, payload)
    if (callBack.status === 204) {
      yield put({type: DELETE_REVIEW_REQUEST_SUCCESS, payload})
    }
  } catch (err) {
    console.error(err)
  }
}


const deleteReviewSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/company-reviews/${item.id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response
}



function* updateReviewSaga({ payload }) {
  try {
    const callBack = yield call(updateReviewSagaAsync, payload)
    if (callBack.success) {
      yield put({type: UPDATE_REVIEW_REQUEST_SUCCESS, payload})
      successUpdatePost('success')
    } else {
      errorAddPost('error')
    }
  } catch (err) {
    console.error(err)
  }
}

const updateReviewSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/company-reviews/${item.id}?_method=PUT`, {
    method: "POST",
    contentType:false,
    processData: false,
    enctype:'multipart/form-data',
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item.data
  })
  return await response.json()
}



function* authSaga() {
  yield takeEvery(GET_REVIEWS_REQUEST, getReviwsSaga)
  yield takeEvery(GET_REVIEW_REQUEST, getReviewSaga)
  yield takeEvery(DELETE_REVIEW_REQUEST, deleteReviewSaga)
  yield takeEvery(UPDATE_REVIEW_REQUEST, updateReviewSaga)
}

export default authSaga
