import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_DASHBOARD_REQUEST_SUCCESS,
  GET_DASHBOARD_REQUEST
} from "./actionTypes"


function* getDashboardSaga({ payload }) {
  try {
    const callBack = yield call(getDashboardSagaAsync, payload)
    if (callBack.success) {
      yield put({type: GET_DASHBOARD_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}


const getDashboardSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/dashboard`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}



function* authSaga() {
  yield takeEvery(GET_DASHBOARD_REQUEST, getDashboardSaga)
}

export default authSaga
