import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
import ecommerceSaga from "./e-commerce/saga"
import contactsSaga from "./contacts/saga"





// MY -------------
import AuthorisationSaga from "./Authorisation/saga"
import ServicesSaga from "./Services/saga"
import GrantsSaga from "./Grants/saga"
import CompanySaga from "./Company/saga"
import PostSaga from "./Post/saga"
import PagesSaga from "./Pages/saga"
import InvestmentsSaga from "./Investments/saga"
import PhotosSaga from "./Photos/saga"
import RequiredLinksSaga from "./RequiredLinks/saga"
import FiltersSaga from "./Filters/saga"
import DashboardSaga from "./Dashboard/saga"
import ReviewSaga from "./Review/saga"


export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(ecommerceSaga),
    fork(contactsSaga),

    fork(AuthorisationSaga),
    fork(ServicesSaga),
    fork(GrantsSaga),
    fork(CompanySaga),
    fork(PostSaga),
    fork(PagesSaga),
    fork(InvestmentsSaga),
    fork(PhotosSaga),
    fork(RequiredLinksSaga),
    fork(FiltersSaga),
    fork(DashboardSaga),
    fork(ReviewSaga),
  ])
}
