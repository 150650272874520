import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_FILTR_REQUEST,
  GET_FILTRES_REQUEST,
  DELETE_FILTR_REQUEST,
  ADD_FILTR_REQUEST_SUCCESS,
  DELETE_FILTR_REQUEST_SUCCESS,
  GET_FILTRES_REQUEST_SUCCESS
} from "./actionTypes"
import {
  successAddNewPost,
  errorAddPost, successUpdatePost, errorNotFoundCompany, varningNotFoundCompany
} from "../../components/Notifications/Resp"
import {
  GET_FILTRES_PRIVATE_SECTOR_REQUEST_SUCCESS, GET_FILTRES_PRODUCT_REQUEST_SUCCESS,
  GET_FILTRES_PUBLIC_SECTOR_REQUEST_SUCCESS, GET_FILTRES_REGION_REQUEST_SUCCESS
} from "../Services/actionTypes"
import { GET_FILTRES_INDUSTRY_REQUEST_SUCCESS, GET_FILTRES_LOCATION_REQUEST_SUCCESS } from "../Investments/actionTypes"




function* addFilterSaga({ payload }) {
  try {
    const callBack = yield call(addFilterSagaAsync, payload)
    if (callBack.success) {
      yield put({type: ADD_FILTR_REQUEST_SUCCESS, callBack})
      successAddNewPost('success')
    } else {
      errorAddPost('error')
    }

  } catch (err) {
    console.error(err)
  }
}

//

const addFilterSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/filters`, {
    method: "POST",
    contentType:false,
    processData: false,
    enctype:'multipart/form-data',
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item
  })
  return await response.json()
}



function* getFiltersSaga({ payload }) {
  try {
    const callBack = yield call(getFiltersSagaAsync, payload)
    if (callBack.success) {
      if(payload.type === 'service'){
        if(payload.type_id === 1) {
          yield put({type: GET_FILTRES_PRIVATE_SECTOR_REQUEST_SUCCESS, callBack})
        }
        else if(payload.type_id === 2) {
          yield put({type: GET_FILTRES_PUBLIC_SECTOR_REQUEST_SUCCESS, callBack})
        }
        else if(payload.type_id === 3) {
          yield put({type: GET_FILTRES_PRODUCT_REQUEST_SUCCESS, callBack})
        }
        else if(payload.type_id === 4) {
          yield put({type: GET_FILTRES_REGION_REQUEST_SUCCESS, callBack})
        }
        else if(payload.type_id === 5) {
          yield put({type: GET_FILTRES_INDUSTRY_REQUEST_SUCCESS, callBack})
        }
        else if(payload.type_id === 6) {
          yield put({type: GET_FILTRES_LOCATION_REQUEST_SUCCESS, callBack})
        }
      }else {
        yield put({type: GET_FILTRES_REQUEST_SUCCESS, callBack})
      }

    }
  } catch (err) {
    console.error(err)
  }
}


const getFiltersSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/filters?data_per_page=1000&page=1&type_id=${item.type_id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}



function* deleteFilterSaga({ payload }) {
  try {
    const callBack = yield call(deleteFilterSagaAsync, payload)
    if (callBack.status === 204) {
      yield put({type: DELETE_FILTR_REQUEST_SUCCESS, payload})
    }
  } catch (err) {
    console.error(err)
  }
}


const deleteFilterSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/filters/${item.id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response
}




function* authSaga() {
  yield takeEvery(ADD_FILTR_REQUEST, addFilterSaga)
  yield takeEvery(GET_FILTRES_REQUEST, getFiltersSaga)
  yield takeEvery(DELETE_FILTR_REQUEST, deleteFilterSaga)
}

export default authSaga
