import { connect, useDispatch } from "react-redux"
import React, { useEffect } from "react"
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
// import { registerOwnerUser, apiError } from "../../store/actions"

// Redux

import { withRouter, Link } from "react-router-dom"


// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"

const Register = props => {
  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      name: "",
      lastName: "",
      password: "",
      rePassword: "",
      phone: "",
      agree: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      let item = {
        values,
        history : props.history
      }

     // dispatch(registerOwnerUser(item))
    }
  })


  useEffect(() => {
    dispatch(apiError(""))
    document.body.className = "authentication-bg"
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = ""
    }
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="22" className="logo logo-dark" />
                  <img src={logolight} alt="" height="22" className="logo logo-light" />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">

                    <h5 className="text-primary">რეგისტრაცია</h5>
                    <p className="text-muted">ობიექტის დასამატებლად გაიარეთ რეგისტრაცია</p>
                  </div>

                  <div className="p-2 mt-4">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {props.Authorisation.registrationError && props.Authorisation.registrationError ? (
                        <Alert color="danger">{props.Authorisation.registrationError}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label">ელ-ფოსტა</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="მაგ. example@gmail.com"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">სახელი</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="შეიყვანეთ თქვენი სახელი"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name ? true : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">გვარი</Label>
                        <Input
                          name="lastName"
                          type="text"
                          placeholder="შეიყვანეთ თქვენი გვარი"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastName || ""}
                          invalid={
                            validation.touched.lastName && validation.errors.lastName ? true : false
                          }
                        />
                        {validation.touched.lastName && validation.errors.lastName ? (
                          <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">პაროლი</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="შეიყვანეთ პაროლი"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">გაიმეორეთ პაროლი</Label>
                        <Input
                          name="rePassword"
                          type="password"
                          placeholder="შეიყვანეთ პაროლი"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.rePassword || ""}
                          invalid={
                            validation.touched.rePassword && validation.errors.rePassword ? true : false
                          }
                        />
                        {validation.touched.rePassword && validation.errors.rePassword ? (
                          <FormFeedback type="invalid">{validation.errors.rePassword}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">ტელეფონი</Label>
                        <Input
                          name="phone"
                          type="text"
                          placeholder="შეიყვანეთ თქვენი ტელეფონის ნომერი"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ""}
                          invalid={
                            validation.touched.phone && validation.errors.phone ? true : false
                          }
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                        ) : null}
                      </div>


                      <div className="mb-3">
                        <Label className="form-label">მომსახურების პირობები:</Label>
                        <Input
                          name="agree"
                          type="checkbox"
                          placeholder="შეიყვანეთ თქვენი ტელეფონის ნომერი"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.agree || ""}
                          invalid={
                            validation.touched.agree && validation.errors.agree ? true : false
                          }
                        />
                        {validation.touched.agree && validation.errors.agree ? (
                          <FormFeedback type="invalid">{validation.errors.agree}</FormFeedback>
                        ) : null}
                      </div>




                      <div className="mt-3 text-end">
                        <button
                          className="btn btn-primary w-sm waves-effect waves-light"
                          type="submit"
                        >
                          რეგისტრაცია
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="text-muted mb-0">
                          <Link to="/login" className="fw-medium text-primary"> ავტორიზაცია</Link>
                        </p>
                      </div>

                    </Form>
                  </div>
                  {/*

                  <div className="p-2 mt-4">
                  <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                     {user && user ? (
                        <Alert color="success">
                          Register Users Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <div className="mb-3">
                      <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                      <Label className="form-label">Username</Label>
                        <Input
                          name="username"
                          type="text"
                          placeholder="Enter username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                      <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="auth-terms-condition-check" />
                        <label className="form-check-label" htmlFor="auth-terms-condition-check">I accept <Link to="#" className="text-dark">Terms and Conditions</Link></label>
                      </div>

                      <div className="mt-3 text-end">
                        <button
                          className="btn btn-primary w-sm waves-effect waves-light"
                          type="submit"
                        >
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <div className="signin-other-title">
                          <h5 className="font-size-14 mb-3 title">Sign up using</h5>
                        </div>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                            >
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li>{" "}
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-info text-white border-info"
                            >
                              <i className="mdi mdi-twitter" />
                            </Link>
                          </li>{" "}
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="text-muted mb-0">Already have an account ? <Link to="/login" className="fw-medium text-primary"> Login</Link></p>
                      </div>

                    </Form>

                  </div>


                  */}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} {process.env.REACT_APP_PROJECT_NAME}. Crafted with <i
                  className="mdi mdi-heart text-danger"></i> by {process.env.REACT_APP_PROJECT_BY_NAME}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}


const mapStateToProps = state => {
  const {
    Authorisation
  } = state
  return {
    Authorisation
  }
}
export default withRouter(connect(mapStateToProps)(Register))

