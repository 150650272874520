import {
  DELETE_GRANT_REQUEST_SUCCESS,
  GET_GRANTS_REQUEST_SUCCESS,
  GET_GRANT_REQUEST_SUCCESS, ADD_GRANTS_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  Error: null,
  result: {
    data:[],
    total:0
  },
  arrayCount: 0,
  updateResult: false,
  data:{
    id:'',
    text:'',
  }
}

const Grants = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_GRANTS_REQUEST_SUCCESS:
      return state = {
        ...state,
        updateResult: !state.updateResult
      }

    case GET_GRANTS_REQUEST_SUCCESS:
      return state = {
        ...state,
        result: action.callBack.result
      }

    case GET_GRANT_REQUEST_SUCCESS:
      return state = {
        ...state,
        data: action.callBack.result.data
      }

    case DELETE_GRANT_REQUEST_SUCCESS:
      state.result.data =  state.result.data.filter(todo => todo.id !== action.payload.id)
      return state = {
        ...state,
      }
    default:
      return state
  }
}

export default Grants
