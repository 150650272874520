import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_REQUIRED_LINK_REQUEST,
  ADD_REQUIRED_LINK_REQUEST_SUCCESS,
  GET_REQUIRED_LINKS_REQUEST,
  GET_REQUIRED_LINK_REQUEST,
  UPDATE_REQUIRED_LINK_REQUEST,
  DELETE_REQUIRED_LINK_REQUEST,
  GET_REQUIRED_LINKS_REQUEST_SUCCESS,
  DELETE_REQUIRED_LINK_REQUEST_SUCCESS,
  GET_REQUIRED_LINK_REQUEST_SUCCESS,
  UPDATE_REQUIRED_LINK_REQUEST_SUCCESS
} from "./actionTypes"

import {
  successAddNewPost,
  errorAddPost, successUpdatePost
} from "../../components/Notifications/Resp"

function* addRequiredLink({ payload }) {
  try {
    const callBack = yield call(addRequiredLinkAsync, payload)
    if (callBack.success) {
      yield put({type: ADD_REQUIRED_LINK_REQUEST_SUCCESS})
      successAddNewPost('success')
    } else {
      errorAddPost('error')
    }
  } catch (err) {
    console.error(err)
  }
}

const addRequiredLinkAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/required-links`, {
    method: "POST",
    contentType:false,
    processData: false,
    enctype:'multipart/form-data',
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item
  })
  return await response.json()
}



function* getRequiredLinkSaga({ payload }) {
  try {
    const callBack = yield call(getRequiredLinkSagaAsync, payload)
    if (callBack.success) {
      yield put({type: GET_REQUIRED_LINKS_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}

const getRequiredLinkSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/required-links?data_per_page=${item.limit}&page=${item.page}&category=${item.category}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}

function* getRequiredLink({ payload }) {
  try {
    const callBack = yield call(getRequiredLinkAsync, payload)
    if (callBack.success) {
      yield put({type: GET_REQUIRED_LINK_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}


const getRequiredLinkAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/required-links/${item.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}



function* deleteRequiredLink({ payload }) {
  try {
    const callBack = yield call(deleteRequiredLinkAsync, payload)
    if (callBack.status === 204) {
      yield put({type: DELETE_REQUIRED_LINK_REQUEST_SUCCESS, payload})
    }
  } catch (err) {
    console.error(err)
  }
}


const deleteRequiredLinkAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/required-links/${item.id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response
}



function* updateRequiredLink({ payload }) {
  try {
    const callBack = yield call(updateRequiredLinkAsync, payload)
    if (callBack.success) {
      yield put({type: UPDATE_REQUIRED_LINK_REQUEST_SUCCESS, payload})
      successUpdatePost('success')
    } else {
      errorAddPost('error')
    }
  } catch (err) {
    console.error(err)
  }
}

const updateRequiredLinkAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/required-links/${item.id}?_method=PUT`, {
    method: "POST",
    contentType:false,
    processData: false,
    enctype:'multipart/form-data',
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item.data
  })
  return await response.json()
}



function* authSaga() {
  yield takeEvery(ADD_REQUIRED_LINK_REQUEST, addRequiredLink)
  yield takeEvery(GET_REQUIRED_LINKS_REQUEST, getRequiredLinkSaga)
  yield takeEvery(DELETE_REQUIRED_LINK_REQUEST, deleteRequiredLink)
  yield takeEvery(GET_REQUIRED_LINK_REQUEST, getRequiredLink)
  yield takeEvery(UPDATE_REQUIRED_LINK_REQUEST, updateRequiredLink)
}

export default authSaga
