import product1 from "../../assets/images/product/img-1.png";
import product2 from "../../assets/images/product/img-2.png";
import product3 from "../../assets/images/product/img-3.png";
import product4 from "../../assets/images/product/img-4.png";
import product5 from "../../assets/images/product/img-5.png";
import product6 from "../../assets/images/product/img-6.png";

const productsData = [
  {
    id: 1,
    image: product1,
    name: "Nike N012 Shoes",
    link: "#",
    rating: 4,
    category: "Nike",
    currentcolor: "Gray",
    islable: true,
    lable: "Trending",
    oldPrice: 280,
    newPrice: 405,
    isOffer: false,
    offer: 0,
    reviews: 234,
    colors: ["dark", "light", "primary"],
    shortspecifications: [
      "High Quality",
      "Leather",
      "All Sizes available",
      "4 Different Color",
    ],
    decription:
      "If several languages coalesce, the grammar of the resulting language is more simple and regular",
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs: [product2, product3, product4],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Gray" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product1, color: "Red" },
      { image: product2, color: "Dark" },
      { image: product3, color: "Purple" },
    ],
  },
  {
    id: 2,
    image: product2,
    name: "Adidas Running Shoes",
    link: "#",
    rating: 3,
    category: "Nike",
    currentcolor: "Black",
    islable: false,
    lable: "",
    oldPrice: 250,
    newPrice: 240,
    isOffer: true,
    offer: 20,
    colors: ["danger", "dark", "light"],
    reviews: 100,
    shortspecifications: [
      "High Quality",
      "Leather",
      "All Sizes available",
      "4 Different Color",
    ],
    decription:
      "If several languages coalesce, the grammar of the resulting language is more simple and regular",
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs: [product1, product3, product4],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Black" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product2, color: "Red" },
      { image: product4, color: "Dark" },
      { image: product3, color: "Purple" },
    ],
  },
  {
    id: 3,
    image: product3,
    rating: 5,
    name: "Puma P103 Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Purple",
    islable: false,
    lable: "",
    oldPrice: 260,
    newPrice: 250,
    isOffer: false,
    offer: 0,
    colors: ["purple", "light", "dark"],
    reviews: 200,
    shortspecifications: [
      "High Quality",
      "Leather",
      "All Sizes available",
      "4 Different Color",
    ],
    decription:
      "If several languages coalesce, the grammar of the resulting language is more simple and regular",
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs: [product1, product2, product4],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Black" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product3, color: "Red" },
      { image: product4, color: "Dark" },
      { image: product1, color: "Purple" },
    ],
  },
  {
    id: 4,
    image: product4,
    rating: 1,
    name: "Sports S120 Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Cyan",
    islable: false,
    lable: "",
    oldPrice: 240,
    newPrice: 230,
    isOffer: false,
    offer: 0,
    colors: ["info", "success"],
    reviews: 150,
    shortspecifications: [
      "High Quality",
      "Leather",
      "All Sizes available",
      "4 Different Color",
    ],
    decription:
      "If several languages coalesce, the grammar of the resulting language is more simple and regular",
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs: [product1, product2, product3],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Cyan" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product4, color: "Red" },
      { image: product1, color: "Dark" },
      { image: product2, color: "Purple" },
    ],
  },
  {
    id: 5,
    image: product5,
    rating: 2,
    name: "Adidas AB23 Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Blue",
    islable: false,
    lable: "",
    oldPrice: 240,
    newPrice: 250,
    isOffer: false,
    offer: 0,
    colors: ["dark", "light", "primary"],
    reviews: 170,
    shortspecifications: [
      "High Quality",
      "Leather",
      "All Sizes available",
      "4 Different Color",
    ],
    decription:
      "If several languages coalesce, the grammar of the resulting language is more simple and regular",
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs: [product5, product2, product3],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Blue" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product5, color: "Red" },
      { image: product2, color: "Dark" },
      { image: product3, color: "Purple" },
    ],
  },
  {
    id: 6,
    image: product6,
    rating: 5,
    name: "Nike N012 Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Gray",
    islable: false,
    lable: "",
    oldPrice: 270,
    newPrice: 260,
    isOffer: true,
    offer: 20,
    colors: ["dark", "light"],
    reviews: 80,
    shortspecifications: [
      "High Quality",
      "Leather",
      "All Sizes available",
      "4 Different Color",
    ],
    decription:
      "If several languages coalesce, the grammar of the resulting language is more simple and regular",
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs: [product6, product4, product3],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Gray" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product6, color: "Red" },
      { image: product4, color: "Dark" },
      { image: product3, color: "Purple" },
    ],
  },
];

const comments = [
  {
    id: 1,
    name: "Samuel",
    description:
      "It will be as simple as in fact, it will be Occidental. It will seem like simplified",
    date: "12 July, 2020",
    review: "4.1",
  },
  {
    id: 2,
    name: "Joseph",
    description: "Sed ut perspiciatis unde omnis iste natus error sit",
    date: "06 July, 2020",
    review: "4.0",
  },
  {
    id: 3,
    name: "Paul",
    description:
      "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet",
    date: "26 June, 2020",
    review: "4.2",
  },
];

const discountData = [
  { label: "Less than 10%", value: 0 },
  { label: "10% or more", value: 10 },
  { label: "20% or more", value: 20 },
  { label: "30% or more", value: 30 },
  { label: "40% or more", value: 40 },
  { label: "50% or more", value: 50 },
];

const colorData = [
  { label: "Black", value: "dark" },
  { label: "White", value: "light" },
  { label: "Gray", value: "secondary" },
  { label: "Blue", value: "primary" },
  { label: "Green", value: "success" },
  { label: "Red", value: "danger" },
  { label: "Yellow", value: "warning" },
  { label: "Purple", value: "purple" },
];

const orderData = [
  {
    id: "customCheck2",
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "2019-10-08",
    total: "$400",
    badgeclass: "success",
    paymentStatus: "Paid",
  },
  {
    id: "customCheck3",
    orderId: "#SK2541",
    billingName: "Jamal Burnett",
    orderdate: "2019-10-07",
    total: "$380",
    badgeclass: "danger",
    paymentStatus: "Chargeback",
  },
  {
    id: "customCheck4",
    orderId: "#SK2542",
    billingName: "Juan Mitchell",
    orderdate: "2019-10-06",
    total: "$384",
    badgeclass: "success",
    paymentStatus: "Paid",
  },
  {
    id: "customCheck5",
    orderId: "#SK2543",
    billingName: "Barry Dick",
    orderdate: "2019-10-05",
    total: "$412",
    badgeclass: "success",
    paymentStatus: "Paid",
  },
  {
    id: "customCheck6",
    orderId: "#SK2544",
    billingName: "Ronald Taylor",
    orderdate: "2019-10-04",
    total: "$404",
    badgeclass: "warning",
    paymentStatus: "Refund",
  },
  {
    id: "customCheck7",
    orderId: "#SK2545",
    billingName: "Jacob Hunter",
    orderdate: "2019-10-04",
    total: "$392",
    badgeclass: "success",
    paymentStatus: "Paid",
  },
  {
    id: "customCheck8",
    orderId: "#SK2546",
    billingName: "William Cruz",
    orderdate: "2019-10-03",
    total: "$374",
    badgeclass: "success",
    paymentStatus: "Paid",
  },
  {
    id: "customCheck9",
    orderId: "#SK2547",
    billingName: "Dustin Moser",
    orderdate: "2019-10-02",
    total: "$350",
    badgeclass: "success",
    paymentStatus: "Paid",
  },
  {
    id: "customCheck10",
    orderId: "#SK2548",
    billingName: "Clark Benson",
    orderdate: "2019-10-01",
    total: "$345",
    badgeclass: "warning",
    paymentStatus: "Refund",
  },
  {
    id: "customCheck11",
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "2019-10-08",
    total: "$400",
    badgeclass: "success",
    paymentStatus: "Paid",
  },
  {
    id: "customCheck12",
    orderId: "#SK2541",
    billingName: "Jamal Burnett",
    orderdate: "2019-10-07",
    total: "$380",
    badgeclass: "danger",
    paymentStatus: "Chargeback",
  },
  {
    id: "customCheck13",
    orderId: "#SK2542",
    billingName: "Juan Mitchell",
    orderdate: "2019-10-06",
    total: "$384",
    badgeclass: "success",
    paymentStatus: "Paid",
  },
  {
    id: "customCheck14",
    orderId: "#SK2543",
    billingName: "Barry Dick",
    orderdate: "2019-10-05",
    total: "$412",
    badgeclass: "success",
    paymentStatus: "Paid",
  },
];

const cartData = {
  products: [
    {
      id: 1,
      img: "img1",
      name: "Half sleeve T-shirt",
      color: "Maroon",
      price: "450",
      data_attr: 2,
      total: 900,
    },
    {
      id: 2,
      img: "img2",
      name: "Light blue T-shirt",
      color: "Light blue",
      price: "225",
      data_attr: 6,
      total: 225,
    },
    {
      id: 3,
      img: "img3",
      name: "Black Color T-shirt",
      color: "Black",
      price: "152",
      data_attr: 2,
      total: 304,
    },
    {
      id: 4,
      img: "img4",
      name: "Hoodie (Blue)",
      color: "Blue",
      price: "145",
      data_attr: 2,
      total: 290,
    },
    {
      id: 5,
      img: "img5",
      name: "Half sleeve T-Shirt",
      color: "Light orange",
      price: "138",
      data_attr: 8,
      total: 138,
    },
    {
      id: 6,
      img: "img6",
      name: "Green color T-shirt",
      color: "Green",
      price: "152",
      data_attr: 2,
      total: 304,
    },
  ],
  orderSummary: {
    grandTotal: "$ 1,857",
    discount: "$ 157",
    shippingCharge: "$ 25",
    estimatedTax: "$ 19.22",
    total: "$ 1744.22",
  },
};

const customerData = [
  {
    id: 1,
    customerid: "#MN0123",
    customerName: "William Shipp",
    email: "WilliamShipp@jourrapide.com",
    joiningDate: "2020-04-14",
    badgeclass: "success",
    customerStatus: "Active",
  },
  {
    id: 2,
    customerid: "#MN0122",
    customerName: "Joe Hardy",
    email: "JoeHardy@dayrep.com",
    joiningDate: "2020-04-14",
    badgeclass: "success",
    customerStatus: "Active",
  },
  {
    id: "3",
    customerid: "#MN0121",
    customerName: "Thomas Hankins",
    email: "ThomasHankins@dayrep.com",
    joiningDate: "2020-04-13",
    badgeclass: "success",
    customerStatus: "Active",
  },
  {
    id: "4",
    customerid: "#MN0120",
    customerName: "Mary Frazier",
    email: "MaryFrazier@armyspy.com",
    joiningDate: "2020-04-12",
    badgeclass: "danger",
    customerStatus: "Deactive",
  },
  {
    id: "5",
    customerid: "#MN0119",
    customerName: "Sam Perry",
    email: "SamPerry@rhyta.com",
    joiningDate: "2020-04-12",
    badgeclass: "success",
    customerStatus: "Active",
  },
  {
    id: "6",
    customerid: "#MN0118",
    customerName: "Muriel Myers",
    email: "MurielMyers@rhyta.com",
    joiningDate: "2020-04-12",
    badgeclass: "danger",
    customerStatus: "Deactive",
  },
  {
    id: "7",
    customerid: "#MN0117",
    customerName: "Jessie Jacobs",
    email: "JessieJacobs@teleworm.us",
    joiningDate: "2020-04-09",
    badgeclass: "success",
    customerStatus: "Active",
  },
  {
    id: "8",
    customerid: "#MN0116",
    customerName: "Edward King",
    email: "EdwardKing@teleworm.us",
    joiningDate: "2020-04-08",
    badgeclass: "success",
    customerStatus: "Active",
  },
  {
    id: "9",
    customerid: "#MN0115",
    customerName: "Stacy Webster",
    email: "StacyWebster@armyspy.com",
    joiningDate: "2020-04-07",
    badgeclass: "danger",
    customerStatus: "Deactive",
  },
  {
    id: "10",
    customerid: "#MN0114",
    customerName: "Amy McDonald",
    email: "AmyMcDonald@jourrapide.com",
    joiningDate: "2020-04-05",
    badgeclass: "success",
    customerStatus: "Active",
  },
  {
    id: "11",
    customerid: "#MN0113",
    customerName: "Terry Brown",
    email: "TerryBrown@dayrep.com",
    joiningDate: "2020-04-02",
    badgeclass: "success",
    customerStatus: "Active",
  },
  {
    id: "12",
    customerid: "#MN0112",
    customerName: "Crissy Holland",
    email: "CrissyHolland@armyspy.com",
    joiningDate: "2020-03-03",
    badgeclass: "danger",
    customerStatus: "Deactive",
  },
];

const shops = [
  {
    id: 1,
    color: "primary",
    name: "Brendle's",
    subtitle: "Clifton Taylor",
    product: 112,
    balance: "13,575",
    profileLink: "#",
  },
  {
    id: 2,
    color: "warning",
    name: "Tech Hifi",
    subtitle: "Karl Early",
    product: 104,
    balance: "11,145",
    profileLink: "#",
  },
  {
    id: 3,
    color: "danger",
    name: "Lafayette",
    subtitle: "Marion Glaze",
    product: 126,
    balance: "12,356",
    profileLink: "#",
  },
  {
    id: 4,
    color: "success",
    name: "Packer",
    subtitle: "Brent Johnson",
    product: 102,
    balance: "11,228",
    profileLink: "#",
  },
  {
    id: 5,
    color: "info",
    name: "Nedick's",
    subtitle: "Kimberly Martinez",
    product: 96,
    balance: "9,235",
    profileLink: "#",
  },
  {
    id: 6,
    color: "dark",
    name: "Hudson's",
    subtitle: "Sarah Stewart",
    product: 120,
    balance: "14,794",
    profileLink: "#",
  },
  {
    id: 7,
    color: "dark",
    name: "Tech Hifi",
    subtitle: "Lauren Doyle",
    product: 104,
    balance: "11,145",
    profileLink: "#",
  },
  {
    id: 8,
    color: "primary",
    name: "Brendle's",
    subtitle: "Elaina Torres",
    product: 112,
    balance: "13,575",
    profileLink: "#",
  },
  {
    id: 9,
    color: "success",
    name: "Lafayette",
    subtitle: "Willie Farber",
    product: 120,
    balance: "12,356",
    profileLink: "#",
  },
];

export {
  productsData,
  comments,
  discountData,
  colorData,
  orderData,
  shops,
  customerData,
  cartData,
};
