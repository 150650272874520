import React, { useEffect, useState, useRef } from "react"
import { connect, useDispatch } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { useFormik } from "formik"
import * as Yup from "yup"
import { addService, getService, updateService } from "../../store/Services/actions"
import Dropzone from "react-dropzone"
import { addGrant, getGrant, updateGrant } from "../../store/Grants/actions"

const Edit = props => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const inputRef = useRef(null)
  const [text, setText] = useState(EditorState.createEmpty())
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFiles, setselectedFiles] = useState([])

  const onEditorText = editorState => {
    setText(editorState)
  }

  useEffect(() => {
    if (props.match.params.id) {
      let item = {
        id: props.match.params.id
      }
      dispatch(getGrant(item))
    }
  }, [props.match.params.id ])

  useEffect(() => {
    setIsLoading(true)
    setText(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(props.Grants.data.text)
        ))
    )

  }, [props.Grants.data.text])


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: props.Grants.data.id || '',
      title:  props.Grants.data.title || '',
      supplier:  props.Grants.data.supplier || '',
      start_date:  props.Grants.data.start_date || '',
      end_date: props.Grants.data.end_date || '',
      meta_title:  props.Grants.data.meta_title || '',
      meta_description:  props.Grants.data.meta_description || '',
      meta_keywords:  props.Grants.data.meta_keywords || '',
      description:  props.Grants.data.description || '',
      grant_type:  props.Grants.data.grant_type || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("შეიყვანეთ სათაური"),
      supplier: Yup.string().required("შეიყვანეთ მომწოდებელი"),
      start_date: Yup.string().required("შეიყვანეთ დაწყების თარიღი"),
      end_date: Yup.string().required("შეიყვანეთ დასრულების თარიღი"),
      description: Yup.string().required("შეიყვანეთ მცირე აღწერილობა").max(250, 'აღწერილობა უნდა იყოს მაქსიმუმ 250 სიმბოლო'),
      // description: Yup.string().required("შეიყვანეთ მცირე აღწერილობა"),
    }),
    onSubmit: (values, { resetForm }) => {

      let sendData = new FormData()
      sendData.append("title", values.title)
      sendData.append("supplier", values.supplier)

      sendData.append("start_date", values.start_date)
      sendData.append("end_date", values.end_date)

      sendData.append("meta_title", values.meta_title)
      sendData.append("meta_description", values.meta_description)
      sendData.append("meta_keywords", values.meta_keywords)

      sendData.append("description", values.description)
      sendData.append("grant_type", values.grant_type)
      sendData.append("text", draftToHtml(convertToRaw(text.getCurrentContent())))

      sendData.append("file", selectedFile)

      dispatch(updateGrant(sendData,props.match.params.id))

    }
  })




  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="" breadcrumbItem="გრანტის რედაქტირება" />
          <Card>
            <CardBody>
              <Row>
                {
                  props.Grants.data.id
                    ?
                    <Col lg={12}>
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >

                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="form-label">სათაური</Label>
                              <Input
                                name="title"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title && validation.errors.title ? true : false
                                }
                              />
                              {validation.touched.title && validation.errors.title ? (
                                <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="form-label">მომწოდებელი</Label>
                              <Input
                                name="supplier"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.supplier || ""}
                                invalid={
                                  validation.touched.supplier && validation.errors.supplier ? true : false
                                }
                              />
                              {validation.touched.supplier && validation.errors.supplier ? (
                                <FormFeedback type="invalid">{validation.errors.supplier}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="form-label">დაწყების თარიღი</Label>
                              <Input
                                name="start_date"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.start_date || ""}
                                invalid={
                                  validation.touched.start_date && validation.errors.start_date ? true : false
                                }
                              />
                              {validation.touched.start_date && validation.errors.start_date ? (
                                <FormFeedback type="invalid">{validation.errors.start_date}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="form-label">დასრულების თარიღი</Label>
                              <Input
                                name="end_date"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.end_date || ""}
                                invalid={
                                  validation.touched.end_date && validation.errors.end_date ? true : false
                                }
                              />
                              {validation.touched.end_date && validation.errors.end_date ? (
                                <FormFeedback type="invalid">{validation.errors.end_date}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">ტეგი Title</Label>
                              <Input
                                name="meta_title"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.meta_title || ""}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">ტეგი Description</Label>
                              <Input
                                name="meta_description"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.meta_description || ""}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">ტეგი Keywords</Label>
                              <Input
                                name="meta_keywords"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.meta_keywords || ""}
                              />

                            </div>
                          </Col>

                          <Col lg="12">
                            <div className="mb-3">
                              <h4>ფილტრი</h4>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="mb-3">
                              <Label className="form-label">ფილტრი</Label>
                              <select
                                name={'grant_type'}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.grant_type || ""}
                                className="form-select">
                                <option>აირჩიერთ ერთ-ერთი</option>
                                <option value={'საგრანტო'}>საგრანტო</option>
                                <option value={'ტენდერი'}>ტენდერი</option>
                                <option value={'სხვა'}>სხვა</option>
                              </select>
                            </div>
                          </Col>



                          <Col lg="12">
                            <div className="mb-3">
                              <Label className="form-label">მცირე აღწერილობა</Label>
                              <Input
                                name="description"
                                className="form-control"
                                type="textarea"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description && validation.errors.description ? true : false
                                }
                              />
                              {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg="12">
                            <div className="mb-3">
                              <Label className="form-label">ტექსტი</Label>
                              <Editor
                                editorState={text}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                onEditorStateChange={onEditorText}
                              />
                            </div>
                          </Col>

                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="form-label">ფაილი</Label>
                              <input ref={inputRef} type="file"
                                     className="form-control"
                                     onChange={(e) => setSelectedFile(e.target.files[0])}
                              />
                              {validation.touched.file && validation.errors.file ? (
                                <FormFeedback type="invalid">{validation.errors.file}</FormFeedback>
                              ) : null}
                            </div>

                            {
                              props.Grants.data.document
                                ?
                                <>
                                  <div className="mb-3">
                                    <p><strong>ფაილი: </strong>
                                      {props.Grants.data.document.upload.name}
                                    </p>
                                  </div>
                                </>
                                :null
                            }

                          </Col>
                        </Row>
                        <Row>
                          <Col className={"pt-3"}>

                          </Col>
                        </Row>
                        <Row>

                          <Col>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                            >
                              დამატება
                            </button>
                          </Col>
                        </Row>


                      </Form>
                    </Col>
                    :null
                }

              </Row>
            </CardBody>
          </Card>

        </div>
      </div>
    </React.Fragment>

  )
}

const mapStateToProps = state => {
  const {
    Grants
  } = state
  return {
    Grants
  }
}
export default withRouter(connect(mapStateToProps)(Edit))

