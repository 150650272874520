import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { updatePage } from "../../store/Pages/actions"
import { withRouter } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import Filters from "../../store/Filters/reducer"
import { addFiltre, deleteFilter, getFilters } from "../../store/Filters/actions"
import FeatherIcon from "feather-icons-react"

const ProductSector = (props) => {
  const dispatch = useDispatch()

  const [typeId, setTypeId] = useState(3)

  useEffect(()=>{
    let item = {
      type_id : typeId
    }
    dispatch(getFilters(item))
  },[])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      filtr_name: '',
    },
    validationSchema: Yup.object({
      filtr_name: Yup.string().required("შეიყვანეთ ფილტრის სახელი"),
    }),
    onSubmit: (values, { resetForm }) => {
      let sendData = new FormData()
      sendData.append("name", values.filtr_name)
      sendData.append("type_id", typeId)
      sendData.append("order_id", 1)

     dispatch(addFiltre(sendData))
      validation.resetForm()
    }

  })


  const handleRemove = (id, key) =>{

    let item = {
      id
    }
    dispatch(deleteFilter(item))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="" breadcrumbItem="ფილტრი: პროდუქტები" />
          <Card>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >

                    <Row>
                      <Col md="8">
                        <div className="mb-3">
                          <Label className="form-label">ფილტრის სახელი</Label>
                          <Input
                            name="filtr_name"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.filtr_name || ""}
                            invalid={
                              validation.touched.filtr_name && validation.errors.filtr_name ? true : false
                            }
                          />
                          {validation.touched.filtr_name && validation.errors.filtr_name ? (
                            <FormFeedback type="invalid">{validation.errors.filtr_name}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3">
                          <Label className="form-label">.</Label>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            დამახსოვრება
                          </button>
                        </div>
                      </Col>


                    </Row>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          ფილტრის სია:
                        </div>
                      </Col>
                    </Row>

                    {
                      props.Filters.result.data.map((item, key) =>{
                        return (
                          <Row key={key} style={{padding:'10px'}}>
                            <Col lg={8}>
                              <strong>{item.name}</strong>
                            </Col>
                            <Col lg={4}>
                              <span
                                onClick={() => {
                                  handleRemove(item.id, key)
                                }}
                                style={{ cursor: "pointer" }} className="px-3 text-primary">
                                <FeatherIcon icon="trash" className="icon-xs icon me-2 text-danger" />
                              </span>
                            </Col>
                          </Row>
                        )
                      })
                    }

                  </Form>
                </Col>
              </Row>

            </CardBody>
          </Card>

        </div>
      </div>
    </React.Fragment>
  )
}


const mapStateToProps = state => {
  const {
    Filters
  } = state
  return {
    Filters
  }
}
export default withRouter(connect(mapStateToProps)(ProductSector))

