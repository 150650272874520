import {
  ADD_REQUIRED_LINK_REQUEST_SUCCESS,
  GET_REQUIRED_LINKS_REQUEST_SUCCESS,
  UPDATE_REQUIRED_LINK_REQUEST_SUCCESS,
  GET_REQUIRED_LINK_REQUEST_SUCCESS,
  DELETE_REQUIRED_LINK_REQUEST_SUCCESS,
  DELETE_FILE_REQUEST_REQUIRED_LINK_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  Error: null,
  result: {
    data:[],
    total:0
  },
  arrayCount: 0,
  updateResult: false,
  updateResultEdit: false,
  data:{
    id:'',
    text:'',
  }
}

const RequiredLinks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_REQUIRED_LINK_REQUEST_SUCCESS:
      return state = {
        ...state,
        updateResult: !state.updateResult
      }
    case GET_REQUIRED_LINKS_REQUEST_SUCCESS:
      return state = {
        ...state,
        result: action.callBack.result
      }

    case UPDATE_REQUIRED_LINK_REQUEST_SUCCESS:
      return state = {
        ...state,
        updateResultEdit: !state.updateResultEdit
      }


    case GET_REQUIRED_LINK_REQUEST_SUCCESS:
      return state = {
        ...state,
        data: action.callBack.result.data
      }

    case DELETE_REQUIRED_LINK_REQUEST_SUCCESS:
      state.result.data = state.result.data.filter(todo => todo.id !== action.payload.id)
      return state = {
        ...state
      }
    case DELETE_FILE_REQUEST_REQUIRED_LINK_SUCCESS:
      let result = [];
      state.data.images.forEach(function(v) {
        if (v.id !== action.payload.id) {
          result.push(v);
        }
      });
      state.data.images = result
      return state = {
        ...state,
      }
    default:
      return state
  }
}

export default RequiredLinks
