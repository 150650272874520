import {
  ADD_SERVICE_REQUEST_SUCCESS,
  DELETE_FILE_REQUEST_SERVICES_SUCCESS,
  DELETE_SERVICE_REQUEST_SUCCESS,
  GET_FILTRES_PRIVATE_SECTOR_REQUEST_SUCCESS, GET_FILTRES_PRODUCT_REQUEST_SUCCESS,

  GET_FILTRES_PUBLIC_SECTOR_REQUEST_SUCCESS, GET_FILTRES_REGION_REQUEST_SUCCESS,
  GET_SERVICE_REQUEST_SUCCESS,
  GET_SERVICES_REQUEST_SUCCESS,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  Error: null,
  result: {
    data:[],
    total:0
  },
  privateSectorResult: {
    data:[],
    total:0
  },
  publicSectorResult: {
    data:[],
    total:0
  },
  productResult: {
    data:[],
    total:0
  },
  regionResult: {
    data:[],
    total:0
  },
  arrayCount: 0,
  updateResult: false,
  updateResultEdit: false,
  data:{
    id:'',
    text:'',
  }
}

const Services = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FILTRES_PRIVATE_SECTOR_REQUEST_SUCCESS:
      return state = {
        ...state,
        privateSectorResult: action.callBack.result
      }
    case GET_FILTRES_PUBLIC_SECTOR_REQUEST_SUCCESS:
      return state = {
        ...state,
        publicSectorResult: action.callBack.result
      }
    case GET_FILTRES_PRODUCT_REQUEST_SUCCESS:
      return state = {
        ...state,
        productResult: action.callBack.result
      }
    case GET_FILTRES_REGION_REQUEST_SUCCESS:
      return state = {
        ...state,
        regionResult: action.callBack.result
      }

    case ADD_SERVICE_REQUEST_SUCCESS:
      return state = {
        ...state,
        updateResult: !state.updateResult
      }

    case UPDATE_SERVICE_REQUEST_SUCCESS:
      return state = {
        ...state,
        updateResultEdit: !state.updateResultEdit
      }

    case GET_SERVICES_REQUEST_SUCCESS:
      return state = {
        ...state,
        result: action.callBack.result
      }

    case GET_SERVICE_REQUEST_SUCCESS:
      return state = {
        ...state,
        data: action.callBack.result.data
      }

    case DELETE_SERVICE_REQUEST_SUCCESS:
      state.result.data =  state.result.data.filter(todo => todo.id !== action.payload.id)
      return state = {
        ...state,
      }
    case DELETE_FILE_REQUEST_SERVICES_SUCCESS:
      let result = [];
      state.data.images.forEach(function(v) {
        if (v.id !== action.payload.id) {
          result.push(v);
        }
      });
      state.data.images = result
      return state = {
        ...state,
      }
    default:
      return state
  }
}

export default Services
