export const ADD_SERVICE_REQUEST = "ADD_SERVICE_REQUEST"
export const ADD_SERVICE_REQUEST_SUCCESS = "ADD_SERVICE_REQUEST_SUCCESS"

export const GET_SERVICES_REQUEST = "GET_SERVICES_REQUEST"
export const GET_SERVICES_REQUEST_SUCCESS = "GET_SERVICES_REQUEST_SUCCESS"

export const GET_SERVICE_REQUEST = "GET_SERVICE_REQUEST"
export const GET_SERVICE_REQUEST_SUCCESS = "GET_SERVICE_REQUEST_SUCCESS"

export const UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST"
export const UPDATE_SERVICE_REQUEST_SUCCESS = "UPDATE_SERVICE_REQUEST_SUCCESS"

export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST"
export const DELETE_SERVICE_REQUEST_SUCCESS = "DELETE_SERVICE_REQUEST_SUCCESS"

export const DELETE_FILE_REQUEST_SERVICES_SUCCESS = "DELETE_FILE_REQUEST_SERVICES_SUCCESS"

export const GET_FILTRES_PRIVATE_SECTOR_REQUEST_SUCCESS = "GET_FILTRES_PRIVATE_SECTOR_REQUEST_SUCCESS"
export const GET_FILTRES_PUBLIC_SECTOR_REQUEST_SUCCESS = "GET_FILTRES_PUBLIC_SECTOR_REQUEST_SUCCESS"
export const GET_FILTRES_PRODUCT_REQUEST_SUCCESS = "GET_FILTRES_PRODUCT_REQUEST_SUCCESS"
export const GET_FILTRES_REGION_REQUEST_SUCCESS = "GET_FILTRES_REGION_REQUEST_SUCCESS"
