import {
  GET_REVIEWS_REQUEST_SUCCESS,
  UPDATE_REVIEW_REQUEST_SUCCESS,
  GET_REVIEW_REQUEST_SUCCESS,
  DELETE_REVIEW_REQUEST_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  Error: null,
  result: {
    data:[],
    total:0
  },
  arrayCount: 0,
  updateResult: false,
  updateResultEdit: false,
  data:{
    id:'',
    text:'',
  }
}

const Reviews = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REVIEWS_REQUEST_SUCCESS:
      return state = {
        ...state,
        result: action.callBack.result
      }

    case UPDATE_REVIEW_REQUEST_SUCCESS:
      return state = {
        ...state,
        updateResultEdit: !state.updateResultEdit
      }


    case GET_REVIEW_REQUEST_SUCCESS:
      return state = {
        ...state,
        data: action.callBack.result.data
      }

    case DELETE_REVIEW_REQUEST_SUCCESS:
      state.result.data = state.result.data.filter(todo => todo.id !== action.payload.id)
      return state = {
        ...state
      }

    default:
      return state
  }
}

export default Reviews
