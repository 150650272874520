import {
  ADD_COMPANY_REQUEST_SUCCESS,
  DELETE_COMPANY_REQUEST_SUCCESS,
  GET_COMPANIES_REQUEST_SUCCESS,
  GET_COMPANY_REQUEST_SUCCESS,
  SEARCH_COMPANY_NULLED_REQUEST,
  SEARCH_COMPANY_REQUEST_SUCCESS, UPDATE_COMPANY_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  Error: null,
  result: {
    data:[],
    total:0
  },
  arrayCount: 0,
  updateResult: false,
  updateResultEdit: false,
  data:{
    id:'',
    text:'',
    information:{},
  },
  dataSearch : []

}

const Company = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_COMPANY_REQUEST_SUCCESS:
      return state = {
        ...state,
        updateResult: !state.updateResult
      }
    case UPDATE_COMPANY_REQUEST_SUCCESS:
      return state = {
        ...state,
        updateResultEdit: !state.updateResultEdit
      }

    case GET_COMPANIES_REQUEST_SUCCESS:
      return state = {
        ...state,
        result: action.callBack.result
      }

    case GET_COMPANY_REQUEST_SUCCESS:
      return state = {
        ...state,
        data: action.callBack.result.data
      }

    case DELETE_COMPANY_REQUEST_SUCCESS:
      state.result.data =  state.result.data.filter(todo => todo.id !== action.payload.id)
      return state = {
        ...state,
      }

    case SEARCH_COMPANY_REQUEST_SUCCESS:
      return state = {
        ...state,
        dataSearch : action.callBack.result.data
      }
    case SEARCH_COMPANY_NULLED_REQUEST:
      return state = {
        ...state,
        dataSearch : []
      }
    default:
      return state
  }
}

export default Company
