import React from "react";
import { Container, Row, Col  } from "reactstrap";

import commingsoon from "../../assets/images/coming-soon-img.png";

const Nulled = () => {

  return (
    <React.Fragment>
      <div className="my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Row className="justify-content-center">
                  <Col lg={4} sm={5}>
                    <div className="maintenance-img">
                      <img src={commingsoon} alt="" className="img-fluid mx-auto d-block" />
                    </div>
                  </Col>
                </Row>

                <h4 className="mt-5">ჩანაწერი არ მოიძებნა</h4>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Nulled;
