import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_INVESTMENT_REQUEST,
  ADD_INVESTMENT_REQUEST_SUCCESS,
  GET_INVESTMENTS_REQUEST,
  GET_INVESTMENT_REQUEST,
  UPDATE_INVESTMENT_REQUEST,
  DELETE_INVESTMENT_REQUEST,
  GET_INVESTMENT_REQUEST_SUCCESS,
  GET_INVESTMENTS_REQUEST_SUCCESS,
  DELETE_INVESTMENT_REQUEST_SUCCESS,
  DELETE_FILE_REQUEST_INVESTMENT_SUCCESS, UPDATE_INVESTMENT_REQUEST_SUCCESS
} from "./actionTypes"


import {
  errorAddPost,
  successAddNewPost, successUpdatePost
} from "../../components/Notifications/Resp"
import {

} from "../Services/actionTypes"


function* addInvestmentSaga({ payload }) {
  try {
    const callBack = yield call(addInvestmentSagaAsync, payload)
    if (callBack.success) {
      yield put({type: ADD_INVESTMENT_REQUEST_SUCCESS})
      successAddNewPost("success")
    }
  } catch (err) {
    console.error(err)
  }
}

const addInvestmentSagaAsync = async (item) => {

  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/investments`, {
    method: "POST",
    contentType: false,
    processData: false,
    enctype: "multipart/form-data",
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item
  })
  return await response.json()
}



function* getInvestmentsSaga({ payload }) {
  try {
    const callBack = yield call(getInvestmentsSagaAsync, payload)
    if (callBack.success) {
      yield put({type: GET_INVESTMENTS_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}

const getInvestmentsSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/investments?data_per_page=${item.limit}&page=${item.page}&type_id=${item.type}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}

function* getInvestmentSaga({ payload }) {
  try {
    const callBack = yield call(getInvestmentSagaAsync, payload)
    if (callBack.success) {
      yield put({type: GET_INVESTMENT_REQUEST_SUCCESS, callBack})
    }
  } catch (err) {
    console.error(err)
  }
}


const getInvestmentSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/investments/${item.id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response.json()
}



function* deleteInvestmentSaga({ payload }) {
  try {
    const callBack = yield call(deleteInvestmentSagaAsync, payload)
    if (callBack.status === 204) {
      yield put({type: DELETE_INVESTMENT_REQUEST_SUCCESS, payload})
    }
  } catch (err) {
    console.error(err)
  }
}


const deleteInvestmentSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/investments/${item.id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    }
  })
  return await response
}



function* updateInvestmentSaga({ payload }) {
  try {
    const callBack = yield call(updateInvestmentSagaAsync, payload)
    if (callBack.success) {
      successUpdatePost('success')
      yield put({type: UPDATE_INVESTMENT_REQUEST_SUCCESS, payload})

    } else {
      errorAddPost('error')
    }
  } catch (err) {
    console.error(err)
  }
}

const updateInvestmentSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/investments/${item.id}?_method=PUT`, {
    method: "POST",
    contentType:false,
    processData: false,
    enctype:'multipart/form-data',
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    body: item.data
  })
  return await response.json()
}



function* authSaga() {
  yield takeEvery(ADD_INVESTMENT_REQUEST, addInvestmentSaga)
  yield takeEvery(GET_INVESTMENTS_REQUEST, getInvestmentsSaga)
  yield takeEvery(DELETE_INVESTMENT_REQUEST, deleteInvestmentSaga)
  yield takeEvery(GET_INVESTMENT_REQUEST, getInvestmentSaga)
  yield takeEvery(UPDATE_INVESTMENT_REQUEST, updateInvestmentSaga)
}

export default authSaga
