import {
  LOGIN_ADMIN_REQUEST,
  CHECK_ADMIN_STATUS_REQUEST,
  LOGOUT_REQUEST
} from "./actionTypes"

export const loginAdmin = (data) => ({
  type: LOGIN_ADMIN_REQUEST,
  payload :data
})

export const checkAdmin = (data) => ({
  type: CHECK_ADMIN_STATUS_REQUEST,
  payload :data
})

export const logOut = (data) => ({
  type: LOGOUT_REQUEST,
  payload :data
})
