import {notification} from 'antd';
import {withTranslation} from "react-i18next";
import i18n from "../../i18n"


export const signUpEmailAlreadyRegistred = type => {
  notification[type]({
    message: 'შეცდომა',
    description: 'არსებული ელ-ფოსტა უკვე დარეგისტირებულია',
  });
};

export const signInEmailOrPasswordIncorect = type => {
  notification[type]({
    message: 'შეცდომა',
    description: 'ელ-ფოსტა ან პაროლი არასწორია',
  });
};
