import React, { useEffect, useState, useRef } from "react"
import { connect, useDispatch } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { useFormik } from "formik"
import * as Yup from "yup"
import Dropzone from "react-dropzone"
import { addRequiredLink } from "../../store/RequiredLinks/actions"

const Add = props => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const inputRef = useRef(null)
  const [text, setText] = useState(EditorState.createEmpty())
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFiles, setselectedFiles] = useState([])

  const onEditorText = editorState => {
    setText(editorState)
  }


  useEffect(() => {
    setText(EditorState.createEmpty())
    inputRef.current.value = null
    validation.resetForm()
    setselectedFiles([])
  }, [props.RequiredLinks.updateResult])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      link: "",
      category_id: "ფინანსები/ბუღალტერია",
      text: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("შეიყვანეთ სათაური"),
      link: Yup.string().required("შეიყვანეთ ბმული"),
    }),
    onSubmit: (values, { resetForm }) => {

      let sendData = new FormData()

      sendData.append("title", values.title)
      sendData.append("link", values.link)
      sendData.append("text", draftToHtml(convertToRaw(text.getCurrentContent())))

      sendData.append("meta_title", values.meta_title === 'undefined' ? '' : values.meta_title)
      sendData.append("meta_description", values.meta_description === 'undefined' ? '' : values.meta_description)
      sendData.append("meta_keywords", values.meta_keywords === 'undefined' ? '' : values.meta_keywords)

      sendData.append("file", selectedFile)
      sendData.append("category", values.category_id)

      selectedFiles.forEach((item) =>{
        sendData.append("images[]", item)
      })

      dispatch(addRequiredLink(sendData))

    }
  })


  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }


  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="სია" breadcrumbItem="საჭირო ბმულის დამატება" />
          <Card>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >

                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label className="form-label">სათაური</Label>
                          <Input
                            name="title"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title || ""}
                            invalid={
                              validation.touched.title && validation.errors.title ? true : false
                            }
                          />
                          {validation.touched.title && validation.errors.title ? (
                            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label className="form-label">ბმული (ლინკი)</Label>
                          <Input
                            name="link"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.link || ""}
                            invalid={
                              validation.touched.link && validation.errors.link ? true : false
                            }
                          />
                          {validation.touched.link && validation.errors.link ? (
                            <FormFeedback type="invalid">{validation.errors.link}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg="3">
                        <div className="mb-3">
                          <Label className="form-label">კატეგორია</Label>
                          <select
                            name="category_id"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.category_id || ""}
                            className="form-control m-input">
                            <option value="ფინანსები/ბუღალტერია">ფინანსები/ბუღალტერია</option>
                            <option value="იურიდიული">იურიდიული</option>
                            <option value="გაყიდვები/მარკეტინგი">გაყიდვები/მარკეტინგი</option>
                            <option value="ადამიანური რესურსები">ადამიანური რესურსები</option>
                            <option value="ექსპორტი">ექსპორტი</option>
                            <option value="სხვა">სხვა</option>
                          </select>
                        </div>
                      </Col>


                      <Col lg="4">
                        <div className="mb-3">
                          <Label className="form-label">თეგი Title</Label>
                          <Input
                            name="meta_title"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.meta_title || ""}
                          />
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label className="form-label">თეგი Description</Label>
                          <Input
                            name="meta_description"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.meta_description || ""}
                          />
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label className="form-label">თეგი Keywords</Label>
                          <Input
                            name="meta_keywords"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.meta_keywords || ""}
                          />

                        </div>
                      </Col>


                      <Col lg="12">
                        <div className="mb-3">
                          <Label className="form-label">ტექსტი</Label>
                          <Editor
                            editorState={text}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            onEditorStateChange={onEditorText}
                          />
                        </div>
                      </Col>
                      <Col lg="12">
                        <h3>ფოტოსურათები</h3>
                      </Col>

                      <Col lg="6">
                        <div className="mb-3">
                          <Label className="form-label">მთავარი ფოტო</Label>
                          <input ref={inputRef} type="file"
                                 className="form-control"
                                 onChange={(e) => setSelectedFile(e.target.files[0])}
                          />
                          {validation.touched.file && validation.errors.file ? (
                            <FormFeedback type="invalid">{validation.errors.file}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg="6">
                        <Label className="form-label">დამატებითი ფოტოსურათები</Label>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted uil uil-cloud-upload"/>
                                </div>
                                <h4>დაამატეთ ფოტოსურათები.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div className="dropzone-previews mt-3" id="file-previews">
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </Col>

                    </Row>
                    <Row>
                      <Col className={"pt-3"}>

                      </Col>
                    </Row>
                    <Row>

                      <Col>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          დამატება
                        </button>
                      </Col>
                    </Row>


                  </Form>
                </Col>
              </Row>

            </CardBody>
          </Card>

        </div>
      </div>
    </React.Fragment>

  )
}

const mapStateToProps = state => {
  const {
    RequiredLinks,
  } = state
  return {
    RequiredLinks,
  }
}
export default withRouter(connect(mapStateToProps)(Add))

