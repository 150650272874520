export const ADD_POST_REQUEST = "ADD_POST_REQUEST"
export const ADD_POST_REQUEST_SUCCESS = "ADD_POST_REQUEST_SUCCESS"

export const GET_POSTS_REQUEST = "GET_POSTS_REQUEST"
export const GET_POSTS_REQUEST_SUCCESS = "GET_POSTS_REQUEST_SUCCESS"

export const GET_POST_REQUEST = "GET_POST_REQUEST"
export const GET_POST_REQUEST_SUCCESS = "GET_POST_REQUEST_SUCCESS"

export const UPDATE_POST_REQUEST = "UPDATE_POST_REQUEST"
export const UPDATE_POST_REQUEST_SUCCESS = "UPDATE_POST_REQUEST_SUCCESS"

export const DELETE_POST_REQUEST = "DELETE_POST_REQUEST"
export const DELETE_POST_REQUEST_SUCCESS = "DELETE_POST_REQUEST_SUCCESS"

export const DELETE_FILE_REQUEST_POST_SUCCESS = "DELETE_FILE_REQUEST_POST_SUCCESS"
