import React, { useEffect, useState, useRef } from "react"
import { connect, useDispatch } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { useFormik } from "formik"
import * as Yup from "yup"
import { addService, getService } from "../../store/Services/actions"
import Dropzone from "react-dropzone"
import { addCompany, getCompany, updateCompany } from "../../store/Company/actions"

const Add = props => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const inputRef = useRef(null)
  const [text, setText] = useState(EditorState.createEmpty())
  const [selectedFile, setSelectedFile] = useState(null)

  const onEditorText = editorState => {
    setText(editorState)
  }

  useEffect(()=>{
    if (isLoading) {
      if (props.match.params.id) {
        let item = {
          id: props.match.params.id
        }
        dispatch(getCompany(item))
      }
    }
  },[props.Company.updateResultEdit])

  useEffect(() => {
    if (props.match.params.id) {
      let item = {
        id: props.match.params.id
      }
      dispatch(getCompany(item))
    }
  }, [props.match.params.id])

  useEffect(()=>{
    setIsLoading(true)
    setText(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(props.Company.data.text)
        ))
    )
  },[props.Company.data.text])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: props.Company.data.id || '',
      title: props.Company.data.title || '',
      phone: props.Company.data.information.phone || '',
      email: props.Company.data.information.email || '',
      webPage: props.Company.data.information.web_page || '',

      meta_title: props.Company.data.meta_title || '',
      meta_description: props.Company.data.meta_description || '',
      meta_keywords: props.Company.data.meta_keywords || '',

      description: props.Company.data.description || '',
      text: props.Company.data.id || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("შეიყვანეთ სათაური"),
      phone: Yup.string().required("შეიყვანეთ ტელეფონი"),
      email: Yup.string().required("შეიყვანეთ ელ-ფოსტა"),
      webPage: Yup.string().required("შეიყვანეთ ვებ-გვერდი"),
      description: Yup.string().required("შეიყვანეთ მცირე აღწერილობა").max(250, 'აღწერილობა უნდა იყოს მაქსიმუმ 250 სიმბოლო'),
    }),
    onSubmit: (values, { resetForm }) => {

      let sendData = new FormData()
      sendData.append("title", values.title)

      sendData.append("meta_title", values.meta_title)
      sendData.append("meta_description", values.meta_description)
      sendData.append("meta_keywords", values.meta_keywords)
      sendData.append("description", values.description)
      sendData.append("text", draftToHtml(convertToRaw(text.getCurrentContent())))
      sendData.append("file", selectedFile)
      sendData.append("identification_number", 1)
      sendData.append("company_type_id", 1)
      sendData.append("company_information", JSON.stringify({
        phone : values.phone,
        email : values.email,
        web_page : values.webPage,
      }))
      dispatch(updateCompany(sendData,props.match.params.id))
    }
  })


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="" breadcrumbItem="კომპანიის დამატება" />
          <Card>
            <CardBody>
              <Row>
                {
                  props.Company.data.id
                    ?
                    <Col lg={12}>
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >

                        <Row>
                          <Col lg="12">
                            <div className="mb-3">
                              <Label className="form-label">სათაური</Label>
                              <Input
                                name="title"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title && validation.errors.title ? true : false
                                }
                              />
                              {validation.touched.title && validation.errors.title ? (
                                <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">თეგი Title</Label>
                              <Input
                                name="meta_title"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.meta_title || ""}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">თეგი Description</Label>
                              <Input
                                name="meta_description"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.meta_description || ""}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">თეგი Keywords</Label>
                              <Input
                                name="meta_keywords"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.meta_keywords || ""}
                              />

                            </div>
                          </Col>

                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">ტელეფონი</Label>
                              <Input
                                name="phone"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={
                                  validation.touched.phone && validation.errors.phone ? true : false
                                }
                              />
                              {validation.touched.phone && validation.errors.phone ? (
                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">ელ-ფოსტა</Label>
                              <Input
                                name="email"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email && validation.errors.email ? true : false
                                }
                              />
                              {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label">ვებ-გვერდი</Label>
                              <Input
                                name="webPage"
                                className="form-control"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.webPage || ""}
                                invalid={
                                  validation.touched.webPage && validation.errors.webPage ? true : false
                                }
                              />
                              {validation.touched.webPage && validation.errors.webPage ? (
                                <FormFeedback type="invalid">{validation.errors.webPage}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg="12">
                            <div className="mb-3">
                              <Label className="form-label">მცირე აღწერილობა</Label>
                              <Input
                                name="description"
                                className="form-control"
                                type="textarea"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description && validation.errors.description ? true : false
                                }
                              />
                              {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg="12">
                            <div className="mb-3">
                              <Label className="form-label">ტექსტი</Label>
                              <Editor
                                editorState={text}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                onEditorStateChange={onEditorText}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="form-label">მთავარი ფოტოსურათი</Label>
                              <input ref={inputRef} type="file"
                                     className="form-control"
                                     onChange={(e) => setSelectedFile(e.target.files[0])}
                              />
                              {validation.touched.file && validation.errors.file ? (
                                <FormFeedback type="invalid">{validation.errors.file}</FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              {
                                props.Company.data.cover
                                  ?   <img
                                    style={{height:'150px'}}
                                    src={`${process.env.REACT_APP_SERVER_URL}/${props.Company.data.cover.upload.path}`}/>
                                  :null
                              }


                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className={"pt-3"}>
                          </Col>
                        </Row>
                        <Row>

                          <Col>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                            >
                              განახლება
                            </button>
                          </Col>
                        </Row>


                      </Form>
                    </Col>
                    :null

                }

              </Row>

            </CardBody>
          </Card>

        </div>
      </div>
    </React.Fragment>

  )
}
const mapStateToProps = state => {
  const {
    Company
  } = state
  return {
    Company
  }
}
export default withRouter(connect(mapStateToProps)(Add))
